
import clsx from "clsx";
import { defineComponent, ref } from "vue";
import {
  IonPage,
  IonContent,
  IonGrid,
  IonCard,
  IonList,
  IonItem,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonLabel,
  IonText,
  toastController,
} from "@ionic/vue";
import { mapGetters, mapActions } from "vuex";
import { trashOutline, copyOutline } from "ionicons/icons";
import { RouterLink } from "vue-router";
import formatDate from "../utils/date";
import Button from "../components/Button.vue";
import Icon from "../components/Icon.vue";
import ClearData from "../components/ClearData.vue";
import type HTMLIonToastElement from "@ionic/core/dist/types/components";
import type HTMLIonListElement from "@ionic/core/dist/types/components";

export default defineComponent({
  name: "HistoryView",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonCard,
    IonList,
    IonItem,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonLabel,
    IonText,
    RouterLink,
    Button,
    Icon,
    ClearData,
  },
  setup() {
    const listRef = ref<HTMLIonListElement | null>(null);
    const closeSlides = () => {
      if (listRef.value) {
        listRef.value.closeSlidingItems();
      }
    };

    return { closeSlides, trashOutline, copyOutline };
  },
  data() {
    return {
      toast: null as HTMLIonToastElement | null,
      toastCtrl: toastController,
    };
  },
  ionViewWillLeave() {
    if (this.toast) {
      this.toastCtrl.dismiss(null, "cancel");
      this.toast = null;
    }
    this.closeSlides();
  },
  computed: {
    ...mapGetters("scans", {
      scans: "scans",
    }),
    ...mapGetters("user", {
      user: "user",
    }),
  },
  methods: {
    clsx,
    formatDate,
    ...mapActions("scans", {
      select: "select",
      remove: "remove",
    }),
    async removeHandler(id: string) {
      this.toast = await this.toastCtrl.create({
        header: "Eintrag löschen",
        message: "Möchten Sie den Eintrag wirklich löschen?",
        position: "bottom",
        color: "dark",
        buttons: [
          {
            side: "start",
            text: "Ja",
            handler: () => {
              this.remove(id);
              this.closeSlides();
            },
          },
          {
            text: "Nein",
            role: "cancel",
          },
        ],
      });
      return this.toast.present();
    },
  },
});
