
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import {
  IonPage,
  IonContent,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  modalController,
} from "@ionic/vue";
import { trashOutline, keyOutline } from "ionicons/icons";
import Button from "../components/Button.vue";
import AccountInfo from "../components/AccountInfo.vue";
import VerifyUserForm from "../components/VerifyUserForm.vue";
import ChangePassword from "../components/ChangePassword.vue";
import DeleteAccount from "../components/DeleteAccount.vue";
import Icon from "../components/Icon.vue";
import type HTMLIonModalElement from "@ionic/core/dist/types/components";

export default defineComponent({
  name: "AccountView",
  components: {
    IonContent,
    IonPage,
    IonCard,
    IonGrid,
    IonRow,
    IonCol,
    Button,
    AccountInfo,
    VerifyUserForm,
    Icon,
  },
  setup() {
    return {
      trashOutline,
      keyOutline,
    };
  },
  data() {
    return {
      modalCtrl: modalController,
      modal: null as HTMLIonModalElement | null,
    };
  },
  computed: {
    ...mapGetters("user", {
      user: "user",
    }),
    ...mapGetters("api", {
      auth: "auth",
    }),
  },
  methods: {
    async showChangePassword() {
      this.modal = await this.modalCtrl.create({
        component: ChangePassword,
        cssClass: "modal",
      });
      return this.modal.present();
    },
    async showDeleteAccount() {
      this.modal = await this.modalCtrl.create({
        component: DeleteAccount,
        cssClass: "modal",
      });
      return this.modal.present();
    },
    hideModal() {
      if (this.modal) {
        this.modalCtrl.dismiss(null, "cancel");
        this.modal = null;
      }
    },
  },
});
