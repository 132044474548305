
import { defineComponent } from "vue";
import {
  IonPage,
  IonHeader,
  IonTitle,
  IonContent,
  IonToolbar,
  IonButtons,
  modalController,
} from "@ionic/vue";
import Button from "./Button.vue";
import Icon from "./Icon.vue";
import ChangePasswordForm from "./ChangePasswordForm.vue";
import { close } from "ionicons/icons";

export default defineComponent({
  name: "ChangePassword",
  components: {
    IonPage,
    IonHeader,
    IonTitle,
    IonContent,
    IonToolbar,
    IonButtons,
    Icon,
    Button,
    ChangePasswordForm,
  },
  setup() {
    return {
      close,
    };
  },
  methods: {
    closeModal() {
      modalController.dismiss();
    },
  },
});
