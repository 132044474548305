
import { defineComponent } from "vue";
import { IonApp, modalController, useIonRouter, useBackButton } from "@ionic/vue";
import { App } from '@capacitor/app';
import { mapGetters, mapActions } from "vuex";
import type HTMLIonModalElement from "@ionic/core/dist/types/components";
import SplashScreen from "./components/SplashScreen.vue";
import Layout from "./Layout.vue";
import { versions } from "./store/api/state";

import Details from "./components/Details.vue";

export default defineComponent({
  name: "App",
  components: {
    SplashScreen,
    IonApp,
    Layout,
  },
  setup() {
    const ionRouter = useIonRouter();
    useBackButton(-1, () => {
      if (!ionRouter.canGoBack()) {
        App.exitApp();
      }
    });
    return {
      versions,
    };
  },
  data() {
    return {
      modalCtrl: modalController,
      modal: null as HTMLIonModalElement | null,
      ready: false,
    };
  },
  computed: {
    ...mapGetters("scans", {
      selected: "selected",
    }),
    ...mapGetters("user", {
      splashScreen: "splashScreen",
    }),
    home() {
      if (this.$route.name === "home") {
        return true;
      } else {
        this.disableSplashScreen();
        return false;
      }
    },
  },
  methods: {
    ...mapActions("api", {
      cancel: "cancel",
    }),
    ...mapActions("user", {
      setSplashScreen: "setSplashScreen",
    }),
    async showDetails() {
      this.modal = await this.modalCtrl.create({
        component: Details,
        cssClass: "modal",
      });
      return this.modal.present();
    },
    hideDetails() {
      if (this.modal) {
        this.modalCtrl.dismiss(null, "cancel");
        this.modal = null;
      }
    },
    disableSplashScreen() {
      this.setSplashScreen(false);
    },
  },
  watch: {
    selected(val) {
      if (val) {
        this.showDetails();
      } else {
        this.hideDetails();
      }
    },
    $route() {
      this.cancel();
    },
  },
});
