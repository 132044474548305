import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b090afa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "splash" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = {
  initial: {
          y: -20,
          opacity: 0,
        },
  enter: {
          opacity: 1,
          y: 0,
          transition: {
            delay: 800,
          },
        }
}
const _hoisted_4 = ["enter"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _directive_motion = _resolveDirective("motion")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Logo),
      _withDirectives((_openBlock(), _createElementBlock("h1", _hoisted_3, [
        _createTextVNode(" PMC Decoder ")
      ])), [
        [_directive_motion]
      ]),
      _withDirectives((_openBlock(), _createElementBlock("h2", {
        initial: {
          y: -20,
          opacity: 0,
        },
        enter: {
          opacity: 0.6,
          y: 0,
          transition: {
            delay: 600,
          },
        }
      }, [
        (_ctx.app.version)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createTextVNode(" Version " + _toDisplayString(_ctx.app.version) + " ", 1),
              (_ctx.app.build)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, "(" + _toDisplayString(_ctx.app.build) + ")", 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ], 8, _hoisted_4)), [
        [_directive_motion]
      ])
    ])
  ]))
}