
import { defineComponent } from "vue";
import { IonGrid, IonRow, IonCol, IonText } from "@ionic/vue";

import { mapActions } from "vuex";
import Button from "./Button.vue";

export default defineComponent({
  name: "DpaForm",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    Button,
  },
  methods: {
    ...mapActions("user", {
      dpa: "dpa",
    }),
  },
});
