
import { defineComponent } from "vue";
import { IonIcon } from "@ionic/vue";

export default defineComponent({
  name: "Button",
  components: {
    IonIcon,
  },
  props: {
    icon: String,
    name: String,
    size: String,
  },
});
