
import clsx from "clsx";
import { defineComponent } from "vue";
import { IonText, IonGrid, IonRow, IonCol } from "@ionic/vue";

import { Form } from "vee-validate";
import yup from "../utils/yup";
import type { FormError } from "../utils/types";
import { mapActions, mapGetters } from "vuex";
import CodeInput from "./CodeInput.vue";
import FormMessage from "./FormMessage.vue";
import Button from "./Button.vue";
import api from "../store/api/constants";

export default defineComponent({
  name: "ForgotPasswordVerifyForm",
  emits: ["cancel"],
  components: {
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    Button,
    Form,
    CodeInput,
    FormMessage,
  },
  data() {
    return {
      initialValues: {
        code: new Array(6).fill(""),
      },
      error: null as FormError | null,
    };
  },
  setup() {
    const schema = yup.object({
      code: yup.array().of(yup.number().integer().max(9).required()),
    });

    return {
      schema,
    };
  },
  mounted() {
    this.clearError();
  },
  computed: {
    ...mapGetters("user", {
      passwordRequest: "passwordRequest",
    }),
    ...mapGetters("api", {
      loading: "loading",
    }),
    path: () => api.paths.forgotPasswordVerify,
  },
  methods: {
    clsx,
    ...mapActions("api", {
      forgotPasswordVerify: "forgotPasswordVerify",
    }),
    ...mapActions("user", {
      message: "message",
      updatePasswordRequest: "passwordRequest",
    }),
    forgotPasswordVerifySubmit(values: any) {
      this.clearError();

      this.forgotPasswordVerify({
        username: this.passwordRequest.username,
        code: values.code ? values.code.join("") : "",
      })
        .then(() => {
          this.updatePasswordRequest({ code: values.code.join("") });
          this.message({
            title: "Passwort zurücksetzen",
            message: "Der eingegebene Code ist gültig.",
            color: "success",
          });
        })
        .catch((error) => {
          this.showError(error.code);
        });
    },
    resetPasswordRequest() {
      this.updatePasswordRequest();
    },
    clearError() {
      this.error = null;
    },
    showError(code: string) {
      switch (code) {
        case "IMS-003":
          this.error = {
            message:
              "Der angegebene Code ist nicht gültig oder möglicherweise abgelaufen.",
          };
          break;
        default:
          this.error = {
            title: "Fehler",
            message:
              "Die Verbindung zum Server konnte nicht hergestellt werden.",
          };
      }
    },
    cancel() {
      this.updatePasswordRequest();
      this.$emit("cancel");
    },
  },
});
