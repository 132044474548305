
import clsx from "clsx";
import { defineComponent, ref } from "vue";
import { IonLabel, IonInput } from "@ionic/vue";

import { FieldArray, Field } from "vee-validate";
import yup from "../utils/yup";

export default defineComponent({
  name: "VerifyUserForm",
  components: {
    IonLabel,
    IonInput,
    FieldArray,
    Field,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
    errors: Object,
    values: Object,
    label: String,
  },
  data() {
    return {
      code: new Array(this.length).fill(""),
    };
  },
  setup() {
    const schema = yup.object().shape({
      code: yup.array().of(yup.number().integer().max(9).required()),
    });

    const inputs = ref([]);

    return {
      schema,
      inputs,
    };
  },
  methods: {
    clsx,
    keyup(event: any, index: number) {
      if (
        this.values?.code[index] !== "" &&
        this.values?.code[index].length === 1 &&
        Number(this.values?.code[index]) > -1
      ) {
        this.focus(index + 1);
      }
    },
    paste(event: any) {
      const clipboardData = event.clipboardData;
      const data = clipboardData.getData("text/plain");

      const numbers = data
        .toString()
        .replace(/[^0-9]+/g, "")
        .split("");

      if (this.values && numbers.length === 6) {
        // eslint-disable-next-line vue/no-mutating-props
        this.values.code = numbers;
        this.focus(this.code.length - 1);
      }
    },
    focus(index: number) {
      const field = this.inputs[index] as typeof IonInput;

      field?.$el.getInputElement().then((item: HTMLInputElement) => {
        item.focus();
        item.select();
      });
    },
  },
});
