<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160 160">
    <g>
      <MotionRect :delay="0" :x="76" :y="66" />

      <MotionRect :delay="1" :x="76" :y="56" />

      <MotionRect :delay="2" :x="66" :y="56" />
      <MotionRect :delay="3" :x="56" :y="66" />
      <MotionRect :delay="4" :x="76" :y="46" />

      <MotionRect :delay="5" :x="76" :y="36" />
      <MotionRect :delay="6" :x="56" :y="56" />

      <MotionRect :delay="7" :x="66" :y="36" />
      <MotionRect :delay="8" :x="56" :y="46" />
      <MotionRect :delay="9" :x="46" :y="56" />

      <MotionRect :delay="10" :x="66" :y="26" />
      <MotionRect :delay="11" :x="46" :y="46" />
      <MotionRect :delay="12" :x="36" :y="56" />

      <MotionRect :delay="13" :x="46" :y="36" />

      <MotionRect :delay="14" :x="46" :y="26" />

      <MotionRect :delay="15" :x="36" :y="26" />
    </g>

    <g>
      <MotionRect :delay="0" :x="96" :y="66" />

      <MotionRect :delay="1" :x="86" :y="46" />
      <MotionRect :delay="2" :x="96" :y="56" />

      <MotionRect :delay="3" :x="116" :y="66" />
      <MotionRect :delay="4" :x="106" :y="56" />
      <MotionRect :delay="5" :x="96" :y="46" />
      <MotionRect :delay="6" :x="86" :y="36" />

      <MotionRect :delay="7" :x="126" :y="66" />

      <MotionRect :delay="8" :x="106" :y="46" />

      <MotionRect :delay="9" :x="116" :y="46" />
      <MotionRect :delay="10" :x="106" :y="36" />

      <MotionRect :delay="11" :x="106" :y="26" />

      <MotionRect :delay="12" :x="116" :y="26" />
      <MotionRect :delay="13" :x="126" :y="36" />
    </g>

    <g>
      <MotionRect :delay="0" :x="66" :y="76" />
      <MotionRect :delay="1" :x="76" :y="86" />

      <MotionRect :delay="2" :x="56" :y="76" />
      <MotionRect :delay="3" :x="66" :y="86" />
      <MotionRect :delay="4" :x="76" :y="96" />

      <MotionRect :delay="5" :x="46" :y="76" />
      <MotionRect :delay="6" :x="56" :y="86" />
      <MotionRect :delay="7" :x="66" :y="96" />
      <MotionRect :delay="8" :x="76" :y="106" />

      <MotionRect :delay="9" :x="46" :y="86" />
      <MotionRect :delay="10" :x="66" :y="106" />

      <MotionRect :delay="11" :x="36" :y="86" />
      <MotionRect :delay="12" :x="66" :y="116" />

      <MotionRect :delay="13" :x="36" :y="96" />
      <MotionRect :delay="14" :x="46" :y="106" />
      <MotionRect :delay="15" :x="56" :y="116" />

      <MotionRect :delay="16" :x="36" :y="116" />
    </g>

    <g>
      <MotionRect :delay="0" :x="86" :y="76" />

      <MotionRect :delay="1" :x="86" :y="86" />

      <MotionRect :delay="2" :x="96" :y="86" />
      <MotionRect :delay="3" :x="106" :y="76" />

      <MotionRect :delay="4" :x="116" :y="76" />
      <MotionRect :delay="5" :x="106" :y="86" />
      <MotionRect :delay="6" :x="96" :y="96" />

      <MotionRect :delay="7" :x="126" :y="76" />
      <MotionRect :delay="8" :x="116" :y="86" />
      <MotionRect :delay="9" :x="86" :y="116" />

      <MotionRect :delay="10" :x="116" :y="96" />
      <MotionRect :delay="11" :x="96" :y="116" />
      <MotionRect :delay="12" :x="106" :y="106" />

      <MotionRect :delay="13" :x="106" :y="116" />
      <MotionRect :delay="14" :x="116" :y="106" />

      <MotionRect :delay="15" :x="126" :y="106" />

      <MotionRect :delay="16" :x="126" :y="116" />
    </g>

    <rect
      width="108"
      height="8"
      rx="2"
      ry="2"
      x="26"
      y="126"
      v-motion
      :initial="{
        opacity: 0,
        width: 0,
        x: -106,
      }"
      :enter="{
        opacity: 1,
        width: 108,
        x: 0,
        transition: {
          delay: 400,
        },
      }"
    />

    <rect
      width="8"
      height="108"
      rx="2"
      ry="2"
      x="26"
      y="26"
      v-motion
      :initial="{
        opacity: 0,
        y: -106,
        height: 8,
      }"
      :enter="{
        opacity: 1,
        height: 108,
        y: 0,
        transition: {
          delay: 300,
        },
      }"
    />

    <g>
      <rect
        v-motion
        :initial="{
          width: 0,
          height: 0,
        }"
        :enter="{
          width: 60,
          height: 12,
          transition: {
            delay: 1000,
          },
        }"
        width="60"
        height="12"
        rx="4"
        ry="4"
        x="0"
        y="0"
      />
      <rect
        v-motion
        :initial="{
          width: 0,
          height: 0,
        }"
        :enter="{
          width: 12,
          height: 60,
          transition: {
            delay: 1000,
          },
        }"
        width="12"
        height="60"
        rx="4"
        ry="4"
        x="0"
        y="0"
      />
    </g>

    <g>
      <rect
        v-motion
        :initial="{
          width: 0,
          height: 0,
          x: 160,
          y: 0,
        }"
        :enter="{
          width: 60,
          height: 12,
          x: 100,
          y: 0,
          transition: {
            delay: 1000,
          },
        }"
        width="60"
        height="12"
        rx="4"
        ry="4"
        x="0"
        y="0"
      />
      <rect
        v-motion
        :initial="{
          width: 0,
          height: 0,
          x: 148,
        }"
        :enter="{
          width: 12,
          height: 60,
          transition: {
            delay: 1000,
          },
        }"
        width="12"
        height="60"
        rx="4"
        ry="4"
        x="0"
        y="0"
      />
    </g>

    <g>
      <rect
        v-motion
        :initial="{
          width: 0,
          height: 0,
          y: 148,
        }"
        :enter="{
          width: 60,
          height: 12,
          transition: {
            delay: 1000,
          },
        }"
        width="60"
        height="12"
        rx="4"
        ry="4"
        x="0"
        y="0"
      />
      <rect
        v-motion
        :initial="{
          width: 0,
          height: 0,
          y: 160,
          x: 148,
        }"
        :enter="{
          width: 12,
          height: 60,
          y: 100,
          transition: {
            delay: 1000,
          },
        }"
        width="12"
        height="60"
        rx="4"
        ry="4"
        x="0"
        y="0"
      />
    </g>

    <g>
      <rect
        v-motion
        :initial="{
          width: 0,
          height: 0,
          x: 160,
          y: 148,
        }"
        :enter="{
          width: 60,
          height: 12,
          x: 100,
          y: 148,
          transition: {
            delay: 1000,
          },
        }"
        width="60"
        height="12"
        rx="4"
        ry="4"
        x="0"
        y="0"
      />
      <rect
        v-motion
        :initial="{
          width: 0,
          height: 0,
          y: 160,
        }"
        :enter="{
          width: 12,
          height: 60,
          y: 100,
          transition: {
            delay: 1000,
          },
        }"
        width="12"
        height="60"
        rx="4"
        ry="4"
        x="0"
        y="0"
      />
    </g>
  </svg>
</template>

<script>
import MotionRect from "./MotionRect.vue";

export default {
  name: "Logo",
  components: {
    MotionRect,
  },
  data() {
    return {
      step: 0,
    };
  },
  mounted() {
    setTimeout(() => {
      this.step = this.step + 1;
    }, 2000);
  },
};
</script>

<style scoped>
.fade-in-enter-active,
.fade-in-leave-active {
  transition: all 0.5s ease;
  opacity: 100;
}

.fade-in-enter-from,
.fade-in-leave-to {
  opacity: 0;
  transform: scale(1.2);
}
</style>
