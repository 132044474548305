
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import { toastController } from "@ionic/vue";
import { trashOutline } from "ionicons/icons";
import Button from "../components/Button.vue";
import Icon from "../components/Icon.vue";
import type HTMLIonToastElement from "@ionic/core/dist/types/components";

export default defineComponent({
  name: "ClearData",
  components: {
    Button,
    Icon,
  },
  setup() {
    return {
      trashOutline,
    };
  },
  data() {
    return {
      toast: null as HTMLIonToastElement | null,
      toastCtrl: toastController,
    };
  },
  methods: {
    ...mapActions("scans", {
      clear: "clear",
    }),
    async clearDataHandler() {
      if (this.toast) {
        this.toast.dismiss();
      }
      this.toast = await this.toastCtrl.create({
        header: "Scans löschen",
        message: "Möchten Sie wirklich alle Scans löschen?",
        position: "bottom",
        color: "dark",
        buttons: [
          {
            side: "start",
            text: "Ja",
            handler: () => {
              this.clear();
              if (this.toast) {
                this.toast.dismiss();
              }
            },
          },
          {
            text: "Nein",
            role: "cancel",
          },
        ],
      });
      return this.toast.present();
    },
  },
});
