
import clsx from "clsx";
import { defineComponent } from "vue";
import { IonGrid, IonRow, IonCol } from "@ionic/vue";

import { mapActions, mapGetters } from "vuex";
import { Form } from "vee-validate";
import { ERR, ApiError, ClientError } from "../utils/error";
import type { FormError } from "../utils/types";
import yup from "../utils/yup";
import CodeInput from "./CodeInput.vue";
import FormMessage from "./FormMessage.vue";
import Button from "./Button.vue";
import api from "../store/api/constants";

const codeLength = 6;

export default defineComponent({
  name: "VerifyUserForm",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    Button,
    Form,
    CodeInput,
    FormMessage,
  },
  data() {
    return {
      initialValues: {
        code: new Array(codeLength).fill(""),
      },
      error: null as FormError | null,
    };
  },
  setup() {
    const schema = yup.object().shape({
      code: yup.array().of(yup.number().integer().max(9).required()),
    });

    return {
      schema,
    };
  },
  mounted() {
    this.clearError();
  },
  computed: {
    ...mapGetters("api", {
      loading: "loading",
    }),
    ...mapGetters("user", {
      user: "user",
    }),
    path: () => {
      return {
        verify: api.paths.verifyUserVerify,
        request: api.paths.verifyUserRequest,
      };
    },
  },
  methods: {
    clsx,
    ...mapActions("api", {
      verifyUserVerify: "verifyUserVerify",
      verifyUserRequest: "verifyUserRequest",
    }),
    ...mapActions("user", {
      verify: "verify",
      message: "message",
    }),
    verifyUserSubmit(values: any) {
      this.clearError();
      this.verifyUserVerify({
        code: values.code ? values.code.join("") : "",
      })
        .then(() => {
          this.message({
            title: "Bestätigung erfolgreich",
            message: "Ihr Benutzerkonto wurde erfolgreich freigeschaltet.",
            color: "success",
          });
        })
        .catch((error: ApiError | ClientError) => {
          this.showError(error.code ?? "");
        });
    },
    verifyUserGenerateCode() {
      this.clearError();
      this.verifyUserRequest()
        .then(() => {
          this.clearForm();
          this.message({
            title: "Bestätigungscode wurde versendet",
            message:
              "Ein neuer Bestätigungscode wurde an Ihre E-Mail-Adresse versendet.",
            color: "success",
          });
        })
        .catch((error: ApiError | ClientError) => {
          this.showError(error.code ?? "");
        });
    },
    clearForm() {
      const form: typeof Form = this.$refs.form as typeof Form;
      form.resetForm();
    },
    clearError() {
      this.error = null;
    },
    showError(code: string) {
      switch (code) {
        case "IMS-003":
          this.error = {
            message: "Der eingegebene Bestätigungscode ist nicht korrekt.",
          };
          break;
        case ERR.VERIFY_USER_REQUEST.toString():
        default:
          this.error = {
            title: "Fehler",
            message:
              "Die Verbindung zum Server konnte nicht hergestellt werden.",
          };
      }
    },
  },
});
