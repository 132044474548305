
import { defineComponent } from "vue";
import { IonPage, IonTabs, IonRouterOutlet, toastController } from "@ionic/vue";
import Header from "./components/Header.vue";
import Navigation from "./components/Navigation.vue";
import Footer from "./components/Footer.vue";
import { mapGetters } from "vuex";
import { Message } from "./utils/types";
import type HTMLIonToastElement from "@ionic/core/dist/types/components";
import { Capacitor } from "@capacitor/core";

export default defineComponent({
  name: "TabsLayout",
  components: {
    IonPage,
    IonTabs,
    IonRouterOutlet,
    Header,
    Navigation,
    Footer,
  },
  data() {
    return {
      isNative: Capacitor.isNativePlatform(),
      toast: null as HTMLIonToastElement | null,
      toastCtrl: toastController,
    };
  },
  computed: {
    ...mapGetters("user", {
      message: "message",
    }),
  },
  methods: {
    async showMessage(msg: Message) {
      this.toast = await this.toastCtrl.create({
        cssClass: "toast",
        header: msg.title || undefined,
        color: msg.color || "dark",
        message: msg.message,
        duration: 5000,
        position: "bottom",
      });
      return this.toast.present();
    },
  },
  watch: {
    message(msg) {
      this.showMessage(msg);
    },
  },
});
