
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import { trashOutline, menuOutline, close } from "ionicons/icons";
import formatDate from "../utils/date";
import ScanActions from "./ScanActions.vue";
import Button from "./Button.vue";
import Icon from "./Icon.vue";
import Loading from "./Loading.vue";
import List from "./List.vue";

export default defineComponent({
  name: "Details",
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    List,
    Button,
    Icon,
    Loading,
    ScanActions,
  },
  setup() {
    return {
      trashOutline,
      menuOutline,
      close,
    };
  },
  computed: {
    ...mapGetters("scans", {
      selected: "selected",
      details: "details",
    }),
  },
  methods: {
    formatDate,
    ...mapActions("scans", {
      select: "select",
      remove: "remove",
    }),
  },
});
