import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_action_sheet = _resolveComponent("ion-action-sheet")!

  return (_ctx.item)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ion_fab_button, {
          size: _ctx.size === 'small' ? 'small' : 'default',
          color: _ctx.color,
          onClick: _ctx.showActions
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              icon: _ctx.menuOutline,
              onClick: _ctx.showActions
            }, null, 8, ["icon", "onClick"])
          ]),
          _: 1
        }, 8, ["size", "color", "onClick"]),
        _createVNode(_component_ion_action_sheet, {
          "is-open": _ctx.isOpenRef,
          buttons: _ctx.buttons,
          cssClass: "action-sheet",
          onDidDismiss: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setOpen(false)))
        }, null, 8, ["is-open", "buttons"])
      ]))
    : _createCommentVNode("", true)
}