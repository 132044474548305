import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, {
    expand: "block",
    color: "danger",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearDataHandler()))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Icon, { icon: _ctx.trashOutline }, null, 8, ["icon"]),
      _createTextVNode("Alle Scans löschen ")
    ]),
    _: 1
  }))
}