import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { createAnimation, IonicVue } from "@ionic/vue";
import { MotionPlugin } from "@vueuse/motion";
import { addIcons } from "ionicons";

addIcons({
  pmc: require("./assets/img/icon-pmc-decoder-scan.svg"),
  bk: require("./assets/img/icon-bauer-kirch.svg"),
});

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme */
import "./theme/body.css";
import "./theme/variables.css";
import "./theme/fonts.css";
import "./theme/default.css";
import "./theme/text.css";
import "./theme/form.css";
import "./theme/modal.css";
import "./theme/popover.css";
import "./theme/toast.css";
import "./theme/animations.css";
/**
 * baseEl is the navigation component (I.e. ion-nav),
 * but you likely will not need it.
 *
 * The opts object also has a `direction`
 * key which is either 'back' or 'forward' if you
 * wanted to do something special based on the direction.
 */
const animation = (baseEl: any, opts: any) => {
  const { enteringEl, leavingEl } = opts;

  const enteringPage = createAnimation("entering-page-animation")
    .addElement(enteringEl)
    .fromTo("transform", "translateY(-20px)", "translateY(0)")
    .fromTo("opacity", 0, 1);

  const leavingPage = createAnimation("leaving-page-animation")
    .addElement(leavingEl)
    .fromTo("transform", "translateY(0)", "translateY(20)")
    .fromTo("opacity", 1, 0);

  return createAnimation("root-transition")
    .duration(250)
    .easing("ease-in-out")
    .addAnimation([enteringPage, leavingPage]);
};

const app = createApp(App)
  .use(IonicVue, {
    animated: true,
    navAnimation: animation,
  })
  .use(MotionPlugin)
  .use(store)
  .use(router);

router.isReady().then(() => {
  app.mount("#app");
});
