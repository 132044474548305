import router from "../../router";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import { Message, PasswordRequest, User, Feature } from "@/utils/types";

class State {
  dpa = false;
  user: User | null = null;
  variant: "LITE" | "DEMO" | "FULL" | null = "LITE";
  splashScreen = true;
  messages: Array<Message> = [];
  passwordRequest: PasswordRequest | null = null;
}

const getters = <GetterTree<State, any>>{
  dpa: (state: State) => state.dpa,
  user: (state: State) => state.user,
  splashScreen: (state: State) => state.splashScreen,
  messages: (state: State) => state.messages,
  message: (state: State) => (state.messages.length ? state.messages[0] : null),
  passwordRequest: (state: State) => state.passwordRequest,
  feature: (state: State) => (feature: string) => {
    return state.user?.features.find(
      (item: Feature) => item.type === feature && item.enabled
    );
  },
};

const actions = <ActionTree<State, any>>{
  dpa({ commit }, dpa: boolean) {
    commit("dpa", dpa);
  },
  user({ commit }, user: User) {
    commit("user", user);
  },
  verify({ commit }, opts) {
    commit("verify", opts);
  },
  passwordRequest({ commit }, opts) {
    commit("passwordRequest", opts);
  },
  setSplashScreen({ commit }, splashScreen: boolean) {
    commit("splashScreen", splashScreen);
  },
  message({ commit }, message: Message) {
    commit("message", message);
  },
  logout({ commit, dispatch }, silent = false) {
    commit("user", null);
    if (!silent) {
      dispatch("message", {
        title: "Erfolgreich ausgeloggt",
        message: "Sie wurden erfolgreich abgemeldet",
        color: "success",
      });
    }
    router.push("/home");
  },
};

const mutations = <MutationTree<State>>{
  dpa(state: State, dpa: boolean) {
    state.dpa = dpa;
  },
  user(state: State, user: User) {
    state.user = user;
  },
  verify(state: State, opts) {
    if (!state.user) {
      return;
    }
    state.user.verified = opts.verified;
  },
  message(state: State, message: Message) {
    state.messages.unshift(message);
  },
  splashScreen(state: State, splashScreen: boolean) {
    state.splashScreen = splashScreen;
  },
  passwordRequest(state: State, opts) {
    if (opts) {
      if (!state.passwordRequest) {
        state.passwordRequest = {
          username: opts.username || "",
          code: opts.code || "",
        };
      } else {
        if (opts.username) {
          state.passwordRequest.username = opts.username;
        }
        if (opts.code) {
          state.passwordRequest.code = opts.code;
        }
      }
    } else {
      state.passwordRequest = null;
    }
  },
};

export default {
  namespaced: true,
  state: new State(),
  getters,
  mutations,
  actions,
};
