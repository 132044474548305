import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import scans from "./scans";
import api from "./api";
import user from "./user";

const store = createStore({
  modules: {
    scans: scans,
    api: api,
    user: user,
  },
  plugins: [
    createPersistedState({
      paths: [
        "scans.scans",
        "api.version",
        "api.auth",
        "user.user",
        "user.dpa",
      ],
    }),
  ],
});

export default store;
