
import clsx from "clsx";
import { defineComponent } from "vue";
import {
  IonText,
  IonLabel,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import yup from "../utils/yup";
import type { FormError } from "../utils/types";
import { mapActions, mapGetters } from "vuex";
import FormMessage from "./FormMessage.vue";
import Button from "./Button.vue";
import api from "../store/api/constants";

export default defineComponent({
  name: "ForgotPasswordRequestForm",
  components: {
    IonText,
    IonLabel,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    Button,
    Form,
    Field,
    ErrorMessage,
    FormMessage,
  },
  data() {
    return {
      error: null as FormError | null,
    };
  },
  setup() {
    const schema = yup.object({
      username: yup.string().email().required(),
    });

    return {
      schema,
    };
  },
  mounted() {
    this.error = null;
  },
  computed: {
    ...mapGetters("user", {
      passwordRequest: "passwordRequest",
    }),
    ...mapGetters("api", {
      loading: "loading",
    }),
    path: () => api.paths.forgotPasswordRequest,
  },
  methods: {
    clsx,
    ...mapActions("api", {
      forgotPasswordRequest: "forgotPasswordRequest",
    }),
    ...mapActions("user", {
      message: "message",
      updatePasswordRequest: "passwordRequest",
    }),
    forgotPasswordRequestSubmit(values: any) {
      this.error = null;

      this.forgotPasswordRequest({
        username: values.username,
      })
        .then(() => {
          this.updatePasswordRequest({ username: values.username });
          this.success();
        })
        .catch((error) => {
          this.showError(error.code);
        });
    },
    success() {
      this.message({
        title: "Passwort zurücksetzen",
        message:
          "Falls die eingegebene E-Mail-Adresse im System existiert, erhalten Sie in Kürze Informationen zum Zurücksetzen Ihres Passworts.",
        color: "success",
      });
    },
    showError(code: string) {
      switch (code) {
        case "IMS-001":
          this.error = {
            message: "Die angegebene E-Mail-Adresse ist nicht registriert.",
          };
          break;
        case "IMS-004":
          this.error = {
            message: "Der Account wurde noch nicht freigeschaltet.",
          };
          break;
        default:
          this.error = {
            title: "Fehler",
            message:
              "Die Verbindung zum Server konnte nicht hergestellt werden.",
          };
      }
    },
    cancel() {
      this.updatePasswordRequest();
      this.$emit("cancel");
    },
  },
});
