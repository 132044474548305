
import clsx from "clsx";
import { defineComponent } from "vue";
import { IonLabel, IonInput, IonGrid, IonRow, IonCol } from "@ionic/vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import { ERR } from "../utils/error";
import yup from "../utils/yup";
import type { FormError } from "../utils/types";
import FormMessage from "./FormMessage.vue";
import Button from "./Button.vue";

import { mapActions, mapGetters } from "vuex";
import api from "../store/api/constants";

export default defineComponent({
  name: "LoginForm",
  emits: ["success", "cancel"],
  components: {
    IonLabel,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    Button,
    Form,
    Field,
    ErrorMessage,
    FormMessage,
  },
  data() {
    return {
      error: null as FormError | null,
    };
  },
  setup() {
    const schema = yup.object({
      username: yup.string().email().required(),
      password: yup.string().required(),
    });

    return {
      schema,
    };
  },
  mounted() {
    this.clearError();
  },
  computed: {
    ...mapGetters("api", {
      loading: "loading",
    }),
    path: () => api.paths.login,
  },
  methods: {
    clsx,
    ...mapActions("api", {
      login: "login",
    }),
    ...mapActions("user", {
      user: "user",
      message: "message",
    }),
    loginSubmit(values: any) {
      this.clearError();

      this.login({
        username: values.username,
        password: values.password,
      })
        .then(() => {
          this.message({
            title: "Login erfolgreich",
            message: "Herzlich willkommen",
            color: "success",
          });
          this.$emit("success");
        })
        .catch((error) => {
          this.showError(error.code);
        });
    },
    clearError() {
      this.error = null;
    },
    showError(code: string) {
      switch (code) {
        case ERR.LOGIN_AUTH:
          this.error = {
            message: "Benutzername oder Passwort sind nicht korrekt.",
          };
          break;
        case ERR.LOGIN_REQUEST:
        default:
          this.error = {
            message:
              "Die Verbindung zum Server konnte nicht hergestellt werden.",
          };
      }
    },
  },
});
