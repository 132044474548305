
import { Pagination } from "swiper";
import { defineComponent } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import ScanItem from "../components/ScanItem.vue";

import "swiper/css";
import "swiper/css/pagination";
import "@ionic/vue/css/ionic-swiper.css";

export default defineComponent({
  name: "HomeView",
  components: {
    ScanItem,
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
});
