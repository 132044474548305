
import { defineComponent } from "vue";
import { app } from "../utils/constants";

export default defineComponent({
  name: "Products",
  setup() {
    return {
      app,
    };
  },
});
