
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import {
  IonTitle,
  IonToolbar,
  IonButtons,
  IonHeader,
  popoverController,
} from "@ionic/vue";
import User from "./User.vue";
import Login from "./Login.vue";
import Button from "./Button.vue";
import Icon from "./Icon.vue";
import { personOutline, logIn, logOut } from "ionicons/icons";
import { versions } from "../store/api/state";

export default defineComponent({
  name: "Header",
  setup() {
    return {
      versions,
      personOutline,
      logIn,
      logOut,
    };
  },
  components: {
    IonTitle,
    IonToolbar,
    IonButtons,
    IonHeader,
    Icon,
    User,
    Button,
  },
  computed: {
    ...mapGetters("api", {
      version: "version",
      loading: "loading",
      error: "error",
    }),
    ...mapGetters("user", {
      user: "user",
    }),
  },
  methods: {
    ...mapActions("api", {
      logout: "logout",
    }),
    home() {
      this.$router.push("/");
    },
    async showLogin(ev: MouseEvent) {
      const popover = await popoverController.create({
        component: Login,
        cssClass: "popover",
        animated: true,
        event: ev,
      });
      return popover.present();
    },
  },
});
