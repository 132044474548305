export default {
  host: process.env.VUE_APP_API_HOST || "https://api.admon.de",
  port: Number(process.env.VUE_APP_API_PORT) || null,
  key: "06d39790-c5cd-431d-880b-95f684c5a56a",
  timeout: {
    default: 3000,
    decode: 5000,
  },
  retries: {
    default: 3,
    auth: 5,
  },
  lifetime: 5,
  paths: {
    auth: "/login/api-key",
    login: "/login",
    logout: "/invoke/ims/logout",
    createUser: "/invoke/ims/create-user",
    deleteUser: "/invoke/ims/delete-user",
    verifyUserRequest: "/invoke/ims/send-verification-email",
    verifyUserVerify: "/invoke/ims/verify-user",
    forgotPasswordRequest: "/invoke/ims/forgot-password/send-email",
    forgotPasswordVerify: "/invoke/ims/forgot-password/verify-code",
    forgotPasswordChange: "/invoke/ims/forgot-password/reset-password",
    changePassword: "/invoke/ims/change-password",
    decode: "/invoke/pmc-decoder/",
  },
};
