import { ScanItem } from "./types";
import formatDate from "./date";

const renderAsText = (item: ScanItem, lineBreak = "\n") => {
  const result = [];

  result.push(item.product?.caption);
  if (item.scan?.date) {
    result.push(formatDate(item.scan?.date, item.scan?.time));
  }
  result.push("---");

  if (item.dea) {
    result.push("Post-Unternehmen: " + item.dea);
  }

  if (item.product?.caption) {
    result.push("Frankierart und Version: " + item.product.caption);
  }

  if (item.customer) {
    result.push("Kundennummer: " + item.customer);
  }

  if (item.newspaperCode) {
    result.push("ZKZ: " + item.newspaperCode);
  }

  if (item.bookletNumber) {
    result.push(
      "Heftnummer: " + item.bookletNumber.toString().padStart(4, "0")
    );
  }

  if (item.subscriberNumber) {
    result.push("Abonnentennummer: " + item.subscriberNumber);
  }

  if (item.costs) {
    result.push(
      "Entgelt oder Frankierwert: " +
        "EUR " +
        item.costs?.euro +
        "," +
        item.costs?.cent.toString().padStart(2, "0")
    );
  }

  if (item.date) {
    result.push("Einlieferungs-/DV-Datum: " + formatDate(item.date));
  }

  if (item.trackingNumber) {
    result.push("Laufende Sendungsnummer: " + item.trackingNumber);
  }

  if (item.bundleDestination) {
    result.push("Destination gem. ZEBU: " + item.bundleDestination);
  }

  if (item.ppl) {
    result.push("---");
    result.push("PPL-Informationen");
    result.push("---");

    if (item.pplVersion !== item.productVersion) {
      result.push(
        "Die im Code verwendete PPL-Version " +
          item.productVersion +
          " ist auf dem System nicht verfügbar."
      );
      result.push(
        "Die folgenden Informationen stammen aus der PPL Version " +
          item.pplVersion +
          "."
      );
      result.push("---");
    } else {
      result.push("PPL-Version: " + item.pplVersion);
    }

    if (item.ppl.productId) {
      result.push("Produktschlüssel: " + item.ppl.productId);
    }

    if (item.ppl.baseProduct) {
      result.push("Basisprodukt: " + item.ppl.baseProduct);
    }

    if (item.ppl.addons) {
      result.push("Zusatzleistung: " + item.ppl.addons);
    }

    if (item.ppl.ekp) {
      result.push("Produkt-EKP: " + item.ppl.ekp);
    }
  }

  result.push("---");
  result.push("Erweitert");
  result.push("---");

  if (item.premiumadressId) {
    result.push("PremiumadressId: " + item.premiumadressId);
  }

  if (item.edsNumber) {
    result.push("EDS-Nummer: " + item.edsNumber);
  }

  if (item.participationNumber) {
    result.push("Teilnahmenummer: " + item.participationNumber);
  }

  if (item.payrollNumber) {
    result.push("Entgeltabrechnungsnummer: " + item.payrollNumber);
  }

  if (item.amNumber) {
    result.push("AM-Nummer: " + item.amNumber);
  }

  if (item.customerOrderNumber) {
    result.push("Kundenauftragsnummer: " + item.customerOrderNumber);
  }

  if (item.itemsInBundle) {
    result.push("Anzahl Sendungen im Bund: " + item.itemsInBundle);
  }

  if (item.bundleWeightInGram) {
    result.push("Bundgewicht in Gramm: " + item.bundleWeightInGram);
  }

  if (item.bundleNumber) {
    result.push("Bundnummer: " + item.bundleNumber);
  }

  if (item.palletNumber) {
    result.push("Palettennummer: " + item.palletNumber);
  }

  if (item.bundleNumberInPallet) {
    result.push("Bundnummer auf Palette: " + item.bundleNumberInPallet);
  }

  if (item.customerData) {
    result.push("Kundenindividuelle Informationen:");
    Object.entries(item.customerData).forEach(([key, val]) => {
      result.push("    " + key.toUpperCase() + ": " + val);
    });
  }

  if (item.recipient) {
    result.push("---");
    result.push("Empfänger");
    result.push("---");

    if (item.recipient.name1) {
      result.push("Name 1: " + item.recipient.name1);
    }

    if (item.recipient.name2) {
      result.push("Name 2: " + item.recipient.name2);
    }

    if (item.recipient.name3) {
      result.push("Name 3: " + item.recipient.name3);
    }

    if (item.recipient.street) {
      result.push("Straßenname: " + item.recipient.street);
    }

    if (item.recipient.houseNumber) {
      result.push("Hausnummer: " + item.recipient.houseNumber);
    }

    if (item.recipient.zipCode) {
      result.push("PLZ: " + item.recipient.zipCode);
    }

    if (item.recipient.city) {
      result.push("Ort: " + item.recipient.city);
    }

    if (item.trackMatchId) {
      result.push("TrackAndMatchSdgId: " + item.trackMatchId);
    }

    if (item.recipient.data) {
      result.push("Kundenindividuelle Informationen:");
      Object.entries(item.recipient.data).forEach(([key, val]) => {
        result.push("    " + key.toUpperCase() + ": " + val);
      });
    }
  }

  result.push("---");
  result.push(item.id || "");

  return result.join(lineBreak);
};

export default renderAsText;
