
import clsx from "clsx";
import { defineComponent } from "vue";
import { IonGrid, IonRow, IonCol, IonLabel, IonInput } from "@ionic/vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import yup from "../utils/yup";
import type { FormError } from "../utils/types";
import { mapActions, mapGetters } from "vuex";
import PasswordValidator from "./PasswordValidator.vue";
import FormMessage from "./FormMessage.vue";
import Button from "./Button.vue";
import api from "../store/api/constants";

export default defineComponent({
  name: "ForgotPasswordChangeForm",
  emits: ["success", "cancel"],
  components: {
    FormMessage,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonInput,
    Button,
    Form,
    Field,
    ErrorMessage,
    PasswordValidator,
  },
  data() {
    return {
      initialValues: {
        code: new Array(6).fill(""),
      },
      error: null as FormError | null,
    };
  },
  setup() {
    const schema = yup.object({
      password: yup
        .string()
        .upperCaseCharacter()
        .lowerCaseCharacter()
        .numericCharacter()
        .min(12, "min-length")
        .required("required")
        .strict(),
      repassword: yup
        .string()
        .required()
        .oneOf([yup.ref("password")]),
    });

    return {
      schema,
    };
  },
  mounted() {
    this.clearError();
  },
  computed: {
    ...mapGetters("user", {
      passwordRequest: "passwordRequest",
    }),
    ...mapGetters("api", {
      loading: "loading",
    }),
    path: () => api.paths.forgotPasswordChange,
  },
  methods: {
    clsx,
    ...mapActions("api", {
      forgotPasswordChange: "forgotPasswordChange",
    }),
    ...mapActions("user", {
      message: "message",
      updatePasswordRequest: "passwordRequest",
    }),
    forgotPasswordChangeSubmit(values: any) {
      this.forgotPasswordChange({
        username: this.passwordRequest.username,
        code: this.passwordRequest.code,
        password: values.password,
      })
        .then(() => {
          this.$emit("success");
          this.resetPasswordRequest();

          this.message({
            title: "Passwort zurücksetzen",
            message: "Das Passwort wurde erfolgreich zurückgesetzt.",
            color: "success",
          });
        })
        .catch((error) => {
          this.showError(error.code);
        });
    },
    resetPasswordRequest() {
      this.updatePasswordRequest();
    },
    clearError() {
      this.error = null;
    },
    showError(code: string) {
      switch (code) {
        case "IMS-003":
          this.error = {
            message: "Der angegebene Code ist nicht mehr gültig.",
          };
          break;
        default:
          this.error = {
            title: "Fehler",
            message:
              "Die Verbindung zum Server konnte nicht hergestellt werden.",
          };
      }
    },
    cancel() {
      this.updatePasswordRequest();
      this.$emit("cancel");
    },
  },
});
