import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ForgotPasswordRequestForm = _resolveComponent("ForgotPasswordRequestForm")!
  const _component_ForgotPasswordVerifyForm = _resolveComponent("ForgotPasswordVerifyForm")!
  const _component_ForgotPasswordChangeForm = _resolveComponent("ForgotPasswordChangeForm")!

  return (_openBlock(), _createElementBlock("section", null, [
    (!_ctx.passwordRequest || !_ctx.passwordRequest.username)
      ? (_openBlock(), _createBlock(_component_ForgotPasswordRequestForm, {
          key: 0,
          onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel')))
        }))
      : (
        _ctx.passwordRequest && _ctx.passwordRequest.username && !_ctx.passwordRequest.code
      )
        ? (_openBlock(), _createBlock(_component_ForgotPasswordVerifyForm, {
            key: 1,
            onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel')))
          }))
        : (
        _ctx.passwordRequest && _ctx.passwordRequest.username && _ctx.passwordRequest.code
      )
          ? (_openBlock(), _createBlock(_component_ForgotPasswordChangeForm, {
              key: 2,
              onSuccess: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('success'))),
              onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('cancel')))
            }))
          : _createCommentVNode("", true)
  ]))
}