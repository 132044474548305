import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ChangePasswordForm = _resolveComponent("ChangePasswordForm")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "secondary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Button, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Icon, { icon: _ctx.close }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Passwort ändern")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { class: "ion-padding" }, {
        default: _withCtx(() => [
          _createVNode(_component_ChangePasswordForm, {
            onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeModal())),
            onSuccess: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeModal()))
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}