import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, {
    class: _normalizeClass(_ctx.isNative ? 'native' : 'browser')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_Header),
          _createVNode(_component_ion_router_outlet, {
            animated: true,
            class: "content"
          }),
          _createVNode(_component_Navigation),
          (!_ctx.isNative)
            ? (_openBlock(), _createBlock(_component_Footer, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}