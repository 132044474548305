import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ScanActions = _resolveComponent("ScanActions")!
  const _component_List = _resolveComponent("List")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, { class: "ion-rounded" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { mode: "ios" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "secondary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Button, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.select()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Icon, { icon: _ctx.close }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.details)
                ? (_openBlock(), _createBlock(_component_ion_title, { key: 0 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.details.product?.caption), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (_ctx.details)
            ? (_openBlock(), _createBlock(_component_ScanActions, {
                key: 0,
                item: _ctx.details,
                class: "actions",
                color: "primary",
                slot: "fixed"
              }, null, 8, ["item"]))
            : _createCommentVNode("", true),
          (_ctx.details)
            ? (_openBlock(), _createBlock(_component_List, {
                key: 1,
                item: _ctx.details
              }, null, 8, ["item"]))
            : (_openBlock(), _createBlock(_component_Loading, {
                key: 2,
                color: "#3cb1e6"
              }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}