import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScanItem = _resolveComponent("ScanItem")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_ctx.items.length)
    ? (_openBlock(), _createBlock(_component_swiper, {
        key: 0,
        modules: _ctx.modules,
        pagination: true,
        loop: true,
        class: "container"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
            return (_openBlock(), _createBlock(_component_swiper_slide, {
              key: index,
              class: "ion-no-padding ion-full-width"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ScanItem, { item: item }, null, 8, ["item"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["modules"]))
    : _createCommentVNode("", true)
}