import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!

  return (_openBlock(), _createBlock(_component_ion_tab_bar, {
    slot: "bottom",
    class: "navigation",
    height: "60"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tab_button, {
        tab: "home",
        href: "/home"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Icon, { icon: _ctx.home }, null, 8, ["icon"]),
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _createTextVNode("Home")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_tab_button, {
        tab: "scanner",
        href: "/scanner",
        disabled: _ctx.disabled
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Icon, { icon: _ctx.camera }, null, 8, ["icon"]),
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _createTextVNode("Scanner")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["disabled"]),
      (_ctx.version !== _ctx.versions.LITE)
        ? (_openBlock(), _createBlock(_component_ion_tab_button, {
            key: 0,
            tab: "history",
            href: "/history",
            disabled: _ctx.disabled
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Icon, { icon: _ctx.list }, null, 8, ["icon"]),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode("Historie")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["disabled"]))
        : _createCommentVNode("", true),
      (_ctx.user)
        ? (_openBlock(), _createBlock(_component_ion_tab_button, {
            key: 1,
            tab: "account",
            href: "/account",
            disabled: _ctx.disabled
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Icon, { icon: _ctx.personOutline }, null, 8, ["icon"]),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode("Account ")
                ]),
                _: 1
              }),
              (_ctx.user && !_ctx.user.verified)
                ? (_openBlock(), _createBlock(_component_ion_badge, {
                    key: 0,
                    color: "danger"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("1")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["disabled"]))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_tab_button, {
        tab: "about",
        href: "/about"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Icon, { icon: _ctx.informationCircleOutline }, null, 8, ["icon"]),
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _createTextVNode("Info")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}