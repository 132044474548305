
import { defineComponent } from "vue";
import { IonFooter, IonToolbar, IonButtons, IonButton } from "@ionic/vue";

export default defineComponent({
  name: "Footer",
  components: {
    IonFooter,
    IonToolbar,
    IonButtons,
    IonButton,
  },
  methods: {
    privacy(event: MouseEvent) {
      event.preventDefault();
      this.$router.push("/privacy");
    },
    imprint(event: MouseEvent) {
      event.preventDefault();
      this.$router.push("/imprint");
    },
  },
});
