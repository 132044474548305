import { State, Auth } from "./state";
import { MutationTree } from "vuex";
import { ApiError } from "../../utils/error";
import { Version } from "./state";

const mutations = <MutationTree<State>>{
  version(state, version: Version) {
    state.version = version;
  },
  auth(state, auth: Auth) {
    state.auth = auth;
  },
  loading(state, loading: string | boolean) {
    state.loading = loading;
  },
  error(state, error: ApiError) {
    state.error = error;
  },
};

export default mutations;
