
import { defineComponent } from "vue";
import { IonPage, IonContent, IonRow, IonCol } from "@ionic/vue";
import Privacy from "../components/Privacy.vue";

export default defineComponent({
  name: "PrivacyView",
  components: {
    IonContent,
    IonPage,
    IonRow,
    IonCol,
    Privacy,
  },
});
