
import { app } from "../utils/constants";
import { defineComponent } from "vue";
import Logo from "./Logo.vue";

export default defineComponent({
  name: "SplashScreen",
  components: {
    Logo,
  },
  setup() {
    return {
      app,
    };
  },
  data() {
    return {
      splashScreen: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.$emit("ready");
    }, 2400);
  },
});
