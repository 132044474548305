import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_ctx.message)
    ? (_openBlock(), _createBlock(_component_ion_card, {
        key: 0,
        color: _ctx.type === 'error' ? 'danger' : 'success',
        class: "error ion-padding ion-no-margin ion-margin-bottom"
      }, {
        default: _withCtx(() => [
          (_ctx.message.title)
            ? (_openBlock(), _createElementBlock("h4", _hoisted_1, _toDisplayString(_ctx.message.title), 1))
            : _createCommentVNode("", true),
          (_ctx.message.message)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.message.message), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["color"]))
    : _createCommentVNode("", true)
}