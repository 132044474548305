
import { defineComponent } from "vue";
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from "@ionic/vue";
import Imprint from "../components/Imprint.vue";

export default defineComponent({
  name: "ImprintView",
  components: {
    IonContent,
    IonPage,
    Imprint,
    IonGrid,
    IonRow,
    IonCol,
  },
});
