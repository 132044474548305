
import { Clipboard } from "@capacitor/clipboard";
import { Share } from "@capacitor/share";
import {
  IonActionSheet,
  IonFabButton,
  IonIcon,
  toastController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { mapActions, mapGetters } from "vuex";
import {
  menuOutline,
  close,
  shareOutline,
  copyOutline,
  printOutline,
  trashOutline,
} from "ionicons/icons";
import renderAsText from "../utils/text";
import type HTMLIonToastElement from "@ionic/core/dist/types/components";

export default defineComponent({
  name: "ScanActions",
  components: { IonActionSheet, IonFabButton, IonIcon },
  props: {
    color: {
      type: String,
      default: () => "primary",
    },
    size: String,
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      toast: null as HTMLIonToastElement | null,
      toastCtrl: toastController,
    };
  },
  setup() {
    const isOpenRef = ref(false);
    const setOpen = (state: boolean) => (isOpenRef.value = state);

    return {
      menuOutline,
      close,
      shareOutline,
      copyOutline,
      printOutline,
      trashOutline,
      isOpenRef,
      setOpen,
    };
  },
  methods: {
    ...mapActions("user", {
      message: "message",
    }),
    ...mapActions("scans", {
      remove: "remove",
      select: "select",
    }),
    showActions(event: MouseEvent) {
      event.stopPropagation();

      this.setOpen(true);
    },
  },
  computed: {
    ...mapGetters("user", {
      feature: "feature",
    }),
    buttons() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const { toastCtrl, remove, select, item, message } = this;
      let { toast } = this;
      const buttons: object[] = [];

      if (this.feature("SHARE")) {
        buttons.push({
          text: "Teilen",
          icon: shareOutline,
          handler() {
            Share.canShare()
              .then(() => {
                Share.share({
                  title: item.product.caption || "PMC Decoder",
                  text: renderAsText(item),
                });
              })
              .catch((error) => {
                // eslint-disable-next-line
                console.error(error);
                message({
                  message: "Inhalte können leider nicht geteilt werden.",
                  color: "warning",
                });
              });
          },
        });
      }

      if (this.feature("COPY")) {
        // @todo error message
        buttons.push({
          text: "Kopieren",
          icon: copyOutline,
          async handler() {
            await Clipboard.write({
              string: renderAsText(item),
            });

            message({
              message: "Das Ergebnis wurde in die Zwischenablage kopiert.",
            });
          },
        });
      }

      buttons.push({
        text: "Löschen",
        role: "destructive",
        icon: trashOutline,
        async handler() {
          toast = await toastCtrl.create({
            header: "Eintrag löschen",
            message: "Möchten Sie den Eintrag wirklich löschen?",
            position: "bottom",
            color: "dark",
            buttons: [
              {
                side: "start",
                text: "Ja",
                handler: () => {
                  remove(item.id);
                  select(null);
                },
              },
              {
                text: "Nein",
                role: "cancel",
              },
            ],
          });
          return toast.present();
        },
      });

      buttons.push({
        text: "Abbrechen",
        icon: close,
        role: "cancel",
      });

      return buttons;
    },
  },
});
