const codes = [
  "4445410830008B13D755004F85AD241D0000020000000001000274657374202020202020202020202020",
  "444541c930008b13d755004f85ad24320000020000000001000274657374202020202020202020202020004fe948616d6275726720202020202020202020202020202020204d61676465627572676572205374722e202020202020332020202020202020205350454354524f20416e616c79746963616c20496e737472756d656e74734865726d616e6e20202020202020202020202020202020202020202020204b656d7065722020202020202020202020202020202020202020202020200000000000000000000000",
  "44454108300165a0bc790000472d254300000301bf2de801000154657374202020202020202020202020",
  "4445410931002715026d0000472d24470001313432372020202020202020202020202020202020202020009c9244fc7373656c646f72662020202020202020202020202020506f737466616368202020202020202020202020202031373031333520202020464c542d4d6574616c6c20476d6248202020202020202020202020202020467269656472696368202020202020202020202020202020202020202020416e746f6e696b2020202020202020202020202020202000001701bf2d772020202020202020202020",
  "4445413030002715026d0000472d07b20001333938353131343830202020202020202020202020202020009c9244fc7373656c646f72662020202020202020202020202020506f737466616368202020202020202020202020202031373031353520202020436f626572636f20446169726965732c20436f62656320446575747363684f74746f2020202020202020202020202020202020202020202020202020426165727363682020202020202020202020202020202000001901bf2d775465737420202020202020",
  "4445413030002715026d0000472d2543000136363532363035333220202020202020202020202020202000bcbd4472656e737465696e6675727420202020202020202020204e6f7264686f6c7465727765672020202020202020203220202020202020202045636b6d616e6e202020202020202020202020202020202020202020202048616e73204a6f616368696d2020202020202020202020202020202020204c6163686d616e6e20202020202020202020202020202000000301bf2de85465737420202020202020",
  "4445413030002715026d0000472d24470001313131303632373230202020202020202020202020202020009c9244fc7373656c646f72662020202020202020202020202020506f737466616368202020202020202020202020202031373031333520202020464c542d4d6574616c6c20476d6248202020202020202020202020202020467269656472696368202020202020202020202020202020202020202020416e746f6e696b2020202020202020202020202020202000001801bf2d775465737420202020202020",
  "444541192d002712039800007be82440000134373038363239363320202020202020202020202020202000abe9446f72746d756e6420202020202020202020202020202020506f737466616368202020202020202020202020202031303039303920202020446f6c657a79636820476d6248202620436f2020202020202020202020205068696c69707020202020202020202020202020202020202020202020204265636b65722020202020202020202020202020202020202020202020202020202020202020202020",
  "4445411a31002715026d0000472d244700013236353630333833322020202020202020202020202020207465737420",
  "44454112300165a0bc7900d2472d25430000030100490100015465737420202020202020202020202020",
  "4445411230012c0440e9004f85ad24190000010300a60100017465737420202020202020202020202020",
  "444541ca30012c0440e9004f85ad0c2e0000020300a50020202020202020202020202020202020202020004fe948616d6275726720202020202020202020202020202020204d61676465627572676572205374722e202020202020332020202020202020205350454354524f20416e616c79746963616c20496e737472756d656e74734865726d616e6e20202020202020202020202020202020202020202020204b656d7065722020202020202020202020202020202020202020202020200000000000000000000000",
  "4445413832002712007a1fba07b301de536a353033333434333536312d323230333134593030303030323736000600178e000001000001000001507265737365202f20454c4e3b3032000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
];

export default codes;
