import { GetterTree, MutationTree, ActionTree } from "vuex";

export interface Product {
  id: number;
  name: string;
  caption: string;
}

export interface Scan {
  id: string;
  product: Product;
  dea: string;
}

class State {
  selected: string | null = null;
  progress: string | null = null;
  debounce: number | null = null;
  scans: Array<Scan> = [];
}

const getters = <GetterTree<State, any>>{
  progress: (state) => state.progress,
  scans: (state) => state.scans,
  last: (state) => state.scans.slice(0, 3),
  selected: (state) => state.selected,
  details: (state) => state.scans.find((item) => item.id === state.selected),
  document: (state) => (id: string) => {
    return state.scans.find((item) => item.id === id);
  },
};

const actions = <ActionTree<State, any>>{
  decode({ dispatch, state }, { code, meta, type, snapshot }) {
    return new Promise((resolve, reject) => {
      if (state.progress === code) {
        return;
      }

      dispatch("progress", code);
      dispatch("api/decode", { code, meta, type }, { root: true })
        .then((result) => {
          const now = new Date();
          result = {
            ...result,
            id: code,
            scan: {
              date: {
                day: now.getDate(),
                month: now.getMonth() + 1,
                year: now.getFullYear(),
              },
              time: {
                hour: now.getHours(),
                minute: now.getMinutes(),
              },
            },
            snapshot: snapshot,
          };

          dispatch("progress", null);
          dispatch("add", result);
          dispatch("select", code);
          resolve(result);
        })
        .catch((error) => {
          reject(error);
          if (state.debounce) {
            clearTimeout(state.debounce);
          }
          state.debounce = setTimeout(() => {
            dispatch("progress", null);
          }, 4000);
        });
    });
  },
  add({ commit, state }, data) {
    if (state.scans.find((item) => item.id === data.id)) {
      commit("replace", data);
    } else {
      commit("add", data);
    }
  },
  remove({ commit }, id: string) {
    commit("remove", id);
  },
  clear({ commit }) {
    commit("clear");
  },
  select({ commit }, id: string) {
    commit("select", id);
  },
  deselect({ commit }) {
    commit("select", null);
  },
  progress({ commit }, code: string | null) {
    commit("progress", code);
  },
};

const mutations = <MutationTree<State>>{
  add(state, result: Scan) {
    state.scans.unshift(result);
  },
  replace(state, result: Scan) {
    state.scans.splice(
      state.scans.findIndex((item) => item.id === result.id),
      1
    );

    state.scans.unshift(result);
  },
  remove(state, id: string) {
    state.scans.splice(
      state.scans.findIndex((item) => item.id === id),
      1
    );
  },
  clear(state) {
    state.scans = [];
  },
  select(state, id: string) {
    state.selected = id;
  },
  progress(state, code: string | null) {
    state.progress = code;
  },
};

export default {
  namespaced: true,
  state: new State(),
  getters,
  actions,
  mutations,
};
