import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "code" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_Field = _resolveComponent("Field")!
  const _component_FieldArray = _resolveComponent("FieldArray")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(_component_ion_label, {
          key: 0,
          position: "stacked",
          for: `${_ctx.name}_0`
        }, {
          default: _withCtx(() => [
            _createTextVNode("Code")
          ]),
          _: 1
        }, 8, ["for"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_FieldArray, { name: _ctx.name }, {
        default: _withCtx(({ fields }) => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fields, (f, index) => {
            return (_openBlock(), _createBlock(_component_Field, {
              key: index,
              name: `${_ctx.name}[${index}]`,
              id: `${_ctx.name}[${index}]`
            }, {
              default: _withCtx(({ field }) => [
                _createVNode(_component_ion_input, _mergeProps({
                  id: `${_ctx.name}_field_${index}`
                }, field, {
                  type: "number",
                  maxlength: "1",
                  clearOnEdit: true,
                  tabindex: index,
                  ref_for: true,
                  ref: 
              (el) => {
                _ctx.inputs[index] = el;
              }
            ,
                  class: 
              _ctx.clsx(
                'form-input ion-text-center focus:ring-secondary',
                _ctx.errors && _ctx.errors[`${_ctx.name}[${index}]`] && 'form-error'
              )
            ,
                  onPaste: 
              (event) => {
                event.stopPropagation();
                event.preventDefault();
                _ctx.paste(event, index);
              }
            ,
                  onKeyup: 
              (event) => {
                _ctx.keyup(event, index);
              }
            
                }), null, 16, ["id", "tabindex", "class", "onPaste", "onKeyup"])
              ]),
              _: 2
            }, 1032, ["name", "id"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["name"])
    ])
  ]))
}