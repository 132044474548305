
import { defineComponent } from "vue";
import { IonCard } from "@ionic/vue";

export default defineComponent({
  name: "FormErrorMessage",
  components: {
    IonCard,
  },
  props: {
    type: {
      type: String,
      default: () => "success",
    },
    message: Object,
  },
});
