
import { defineComponent, ref } from "vue";
import { mapActions, mapGetters } from "vuex";
import {
  IonPage,
  IonCard,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
} from "@ionic/vue";
import Button from "../components/Button.vue";
import RegisterForm from "../components/RegisterForm.vue";
import VerifyUserForm from "../components/VerifyUserForm.vue";
import Icon from "../components/Icon.vue";
import Brand from "../components/Brand.vue";
import Products from "../components/Products.vue";
import Slider from "../components/Slider.vue";
import type HTMLIonContentElement from "@ionic/core/dist/types/components";
import type HTMLIonRowElement from "@ionic/core/dist/types/components";
import { versions } from "../store/api/state";

export default defineComponent({
  name: "HomeView",
  components: {
    IonCard,
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    Button,
    Icon,
    RegisterForm,
    VerifyUserForm,
    Brand,
    Products,
    Slider,
  },
  setup() {
    const contentRef = ref<HTMLIonContentElement | null>(null);
    const registerRef = ref<HTMLIonRowElement | null>(null);

    return {
      versions,
      content: contentRef.value,
      register: registerRef.value,
    };
  },
  computed: {
    ...mapGetters("api", {
      version: "version",
    }),
    ...mapGetters("scans", {
      last: "last",
    }),
    ...mapGetters("user", {
      user: "user",
    }),
  },
  methods: {
    ...mapActions("user", {
      acceptPrivacy: "acceptPrivacy",
    }),
    scanner() {
      this.$router.push("/scanner");
    },
  },
});
