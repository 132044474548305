
import { defineComponent } from "vue";

export default defineComponent({
  name: "Privacy",
  methods: {
    imprint(event: MouseEvent) {
      event.preventDefault();
      this.$router.push("imprint");
    },
  },
});
