import axios from "axios";
import axiosRetry from "axios-retry";
import api from "./constants";

import { ActionTree } from "vuex";
import { decodeJwt } from "jose";
import { State } from "./state";

import { ERR, ApiError, ClientError } from "../../utils/error";
import { User, UserToken } from "../../utils/types";

const actions = <ActionTree<State, any>>{
  setVersion({ commit }, version) {
    commit("version", version);
  },
  auth: async function ({ commit, state }): Promise<boolean> {
    if (
      state?.auth?.created &&
      state?.auth?.expires &&
      new Date(state?.auth?.created).getTime() >
        new Date().getTime() - api.lifetime * 60 * 1000 &&
      new Date(state?.auth?.expires).getTime() > new Date().getTime()
    ) {
      return true;
    }

    axiosRetry(axios, {
      retries: api.retries.auth,
      retryDelay: axiosRetry.exponentialDelay,
    });

    try {
      const response = await axios({
        method: "post",
        url: api.host + (api.port ? `:${api.port}` : "") + api.paths.auth,
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": api.key,
        },
        withCredentials: false,
        timeout: api.timeout.default,
      });

      if (
        response &&
        response.data &&
        response.data.successful &&
        response.data.token &&
        response.data.lifetime
      ) {
        const now = new Date();
        commit("auth", {
          token: response.data.token,
          created: now.getTime(),
          expires: now.getTime() + response.data.lifetime * 1000,
        });
        return true;
      }
    } catch (error) {
      commit("auth", null);
      return false;
    }
    commit("auth", null);
    return false;
  },
  async request({ dispatch, state }, { method, path, data }) {
    dispatch("loading", path);

    if (!api.host) {
      throw new ApiError(ERR.NETWORK_RESPONSE, null, "Missing api host");
    }

    if (!api.key) {
      throw new ApiError(ERR.NETWORK_RESPONSE, null, "Missing api key");
    }

    await dispatch("auth");

    return new Promise((resolve, reject) => {
      if (!state.auth) {
        reject(new ApiError(ERR.NETWORK_REQUEST, null, "Connection refused"));
        dispatch("loading", false);
        return;
      }

      if (state.request) {
        state.request.cancel();
      }

      state.request = axios.CancelToken.source();

      axiosRetry(axios, {
        retries: api.retries.default,
        retryDelay: axiosRetry.exponentialDelay,
      });

      axios({
        method,
        url: api.host + (api.port ? `:${api.port}` : "") + path,
        data: data || {},
        cancelToken: state.request.token,
        withCredentials: false,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.auth?.token}`,
          "X-API-Key": api.key,
        },
        timeout: api.timeout.decode ? api.timeout.decode : api.timeout.default,
      })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(new ApiError(ERR.NETWORK_RESPONSE));
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            return;
          }

          let code: string = ERR.NETWORK_REQUEST;
          let message = "";

          const data = error.response?.data || null;

          if (data?.code) {
            code = data.code;
          }

          if (data?.message) {
            message = data.message;
          }

          if (data?.errors?.length) {
            code = data.errors[0].code;
            message = data.errors[0].message;
          }

          reject(new ClientError(code, message));
        })
        .finally(() => {
          dispatch("loading", false);
        });
    });
  },
  login({ dispatch, commit }, { username, password }) {
    return new Promise((resolve, reject) => {
      dispatch("request", {
        method: "post",
        path: api.paths.login,
        data: {
          username,
          password,
        },
      })
        .then((result) => {
          if (result.successful) {
            const now = new Date();
            commit("auth", {
              token: result.token,
              created: now.getTime(),
              expires: now.getTime() + result.lifetime * 1000,
            });
            const userdata: UserToken = decodeJwt(result.token);
            const user: User = {
              username: userdata.preferred_username || username,
              firstname: userdata.given_name || "",
              lastname: userdata.family_name || "",
              name: userdata.name || "",
              email: userdata.email || username,
              verified: userdata["is-user-verified"] === "true" || false,
              features: [
                { type: "DEBUG", enabled: false },
                { type: "SHARE", enabled: true },
                { type: "COPY", enabled: true },
                { type: "DROP", enabled: username.includes("@bauer-kirch.de") },
              ],
            };
            commit("user/user", user, { root: true });

            resolve(true);
          } else {
            reject(new ClientError(ERR.LOGIN_AUTH));
          }
        })
        .catch((error) => {
          if (error.getCode() === "WSG-004") {
            reject(new ClientError(ERR.LOGIN_AUTH));
            return;
          }
          reject(new ApiError(ERR.LOGIN_REQUEST));
        });
    });
  },
  logout({ commit, dispatch }, silent = false) {
    return new Promise(() => {
      dispatch("request", {
        method: "post",
        path: api.paths.logout,
      })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        })
        .finally(() => {
          commit("auth", null);
          dispatch("user/logout", silent, { root: true });
        });
    });
  },
  forgotPasswordRequest({ dispatch }, { username }) {
    return new Promise((resolve, reject) => {
      dispatch("request", {
        method: "post",
        path: api.paths.forgotPasswordRequest,
        data: {
          username,
        },
      })
        .then((data) => {
          resolve(data);
        })
        .catch(reject);
    });
  },
  forgotPasswordVerify({ dispatch }, { username, code }) {
    return new Promise((resolve, reject) => {
      dispatch("request", {
        method: "post",
        path: api.paths.forgotPasswordVerify,
        data: {
          username,
          code,
        },
      })
        .then((data) => {
          resolve(data);
        })
        .catch(reject);
    });
  },
  forgotPasswordChange({ dispatch }, { username, password, code }) {
    return new Promise((resolve, reject) => {
      dispatch("request", {
        method: "post",
        path: api.paths.forgotPasswordChange,
        data: {
          username,
          password,
          code,
        },
      })
        .then((data) => {
          resolve(data);
        })
        .catch(reject);
    });
  },
  changePassword({ dispatch, rootGetters }, { current, password }) {
    return new Promise((resolve, reject) => {
      const user = rootGetters["user/user"];

      if (!user) {
        dispatch("logout", true);
      }

      dispatch("request", {
        method: "post",
        path: api.paths.changePassword,
        data: {
          username: user.username,
          currentPassword: current,
          newPassword: password,
        },
      })
        .then((data) => {
          dispatch("logout", false, { root: true });
          resolve(data);
        })
        .catch(reject);
    });
  },
  createUser({ dispatch }, { username, password }) {
    return new Promise((resolve, reject) => {
      dispatch("request", {
        method: "post",
        path: api.paths.createUser,
        data: {
          username,
          password,
          email: username,
        },
      })
        .then((data) => {
          resolve(data);
          dispatch("login", { username, password }).catch(reject);
        })
        .catch((error) => {
          if (error.getCode() === "IMS-002") {
            reject(new ApiError(ERR.REGISTER_ALREADY_EXISTS));
            return;
          }
          reject(new ApiError(ERR.REGISTER_REQUEST));
        });
    });
  },
  deleteUser({ dispatch, rootGetters }) {
    return new Promise((resolve, reject) => {
      const user = rootGetters["user/user"];

      if (!user) {
        dispatch("logout", true);
      }

      dispatch("request", {
        method: "delete",
        path: api.paths.deleteUser,
        data: {
          username: user.username,
        },
      })
        .then((data) => {
          resolve(data);

          dispatch(
            "user/message",
            {
              title: "Abmeldung erfolgreich",
              message: "Der Account wurde erfolgreich gelöscht",
              color: "success",
            },
            { root: true }
          );
        })
        .catch(reject)
        .finally(() => {
          dispatch("logout", true);
        });
    });
  },
  verifyUserVerify({ dispatch, rootGetters }, { code }) {
    return new Promise((resolve, reject) => {
      const user = rootGetters["user/user"];

      if (!user) {
        dispatch("logout", true);
      }

      dispatch("request", {
        method: "post",
        path: api.paths.verifyUserVerify,
        data: {
          code,
          username: user.username,
        },
      })
        .then((data) => {
          dispatch("user/verify", { verified: true }, { root: true });
          resolve(data);
        })
        .catch(reject);
    });
  },
  verifyUserRequest({ dispatch, rootGetters }) {
    return new Promise((resolve, reject) => {
      const user = rootGetters["user/user"];

      if (!user) {
        dispatch("logout", true);
      }

      dispatch("request", {
        method: "post",
        path: api.paths.verifyUserRequest,
        data: {
          username: user.username,
        },
      })
        .then((data) => {
          if (data?.success) {
            dispatch("user/verify", { verified: false }, { root: true });
            resolve(data);
          } else {
            reject();
          }
        })
        .catch(reject);
    });
  },
  decode({ dispatch }, { code, type, meta }) {
    return new Promise((resolve, reject) => {
      dispatch("request", {
        method: "post",
        path: api.paths.decode,
        data: {
          type,
          code,
          meta,
        },
      })
        .then((data) => {
          if (data.result) {
            if (data.ok) {
              resolve(data.result);
            } else {
              switch (data.code) {
                case 401:
                case 403:
                case 404:
                case 405:
                case 406:
                  reject(
                    new ClientError(
                      "8" + data.code,
                      null,
                      data.result,
                      data.meta
                    )
                  );
                  break;
                default:
                  reject(
                    new ClientError(
                      ERR.DECODE_RESPONSE,
                      null,
                      data.result,
                      data.meta
                    )
                  );
              }
            }
          } else {
            reject(new ClientError(ERR.DECODE_RESPONSE));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loading({ commit }, loading) {
    commit("loading", loading);
  },
  cancel({ dispatch, state }) {
    if (state.request) {
      state.request.cancel();
    }

    dispatch("loading", false);
  },
};

export default actions;
