const app = {
  version: process.env.VUE_APP_VERSION || null,
  build: process.env.VUE_APP_BUILD || null,
};

const chars = {
  D: {
    dec: 68,
  },
  E: {
    dec: 69,
  },
  A: {
    dec: 65,
  },
};

const barcodeLengths = {
  "22x22": 28,
  "26x26": 42,
  "48x48": 172,
  "52x52": 202,
};

export { app, chars, barcodeLengths };
