
import clsx from "clsx";
import { close, checkmark } from "ionicons/icons";
import { defineComponent } from "vue";
import {
  IonGrid,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonInput,
  IonRow,
  IonCol,
  IonText,
} from "@ionic/vue";

import PasswordValidator from "./PasswordValidator.vue";
import { ERR, ApiError } from "../utils/error";
import { Form, Field, ErrorMessage } from "vee-validate";
import type { FormError } from "../utils/types";
import yup from "../utils/yup";
import { mapActions, mapGetters } from "vuex";
import FormMessage from "./FormMessage.vue";
import Button from "./Button.vue";
import api from "../store/api/constants";

export default defineComponent({
  name: "RegisterForm",
  components: {
    IonGrid,
    IonItem,
    IonLabel,
    IonCheckbox,
    IonInput,
    IonRow,
    IonCol,
    IonText,
    Form,
    Field,
    Button,
    ErrorMessage,
    PasswordValidator,
    FormMessage,
  },
  data() {
    return {
      values: {
        username: "",
        password: "",
        repassword: "",
      },
      checkbox: false,
      privacy: false,
      error: null as FormError | null,
    };
  },
  setup() {
    const schema = yup.object({
      username: yup.string().email().required(),
      password: yup
        .string()
        .upperCaseCharacter()
        .lowerCaseCharacter()
        .numericCharacter()
        .min(12, "min-length")
        .required("required")
        .strict(),
      repassword: yup
        .string()
        .required()
        .oneOf([yup.ref("password")]),
    });

    return {
      checkmark,
      close,
      schema,
    };
  },
  mounted() {
    this.clearError();
  },
  computed: {
    ...mapGetters("api", {
      loading: "loading",
    }),
    path: () => api.paths.createUser,
  },
  methods: {
    clsx,
    ...mapActions("api", {
      login: "login",
      createUser: "createUser",
    }),
    ...mapActions("user", {
      message: "message",
    }),
    registerSubmit(values: any) {
      if (!this.privacy || !this.checkbox) {
        return;
      }

      this.clearError();

      this.createUser({
        username: values.username,
        password: values.password,
        email: values.username,
      })
        .then(() => {
          this.message({
            title: "Registrierung erfolgreich",
            message:
              "Sie erhalten in Kürze eine E-Mail, um Ihren Account freizuschalten.",
            color: "success",
          });
        })
        .catch((error) => {
          this.showError(error);
        });
    },
    clearError() {
      this.error = null;
    },
    showError(error: ApiError) {
      switch (error.code) {
        case ERR.REGISTER_ALREADY_EXISTS.toString():
          this.error = {
            message: "Die angegebene E-Mail-Adresse ist bereits registriert.",
          };
          break;
        default:
          this.error = {
            title: "Fehler",
            message:
              "Die Verbindung zum Server konnte nicht hergestellt werden.",
          };
      }
    },
  },
});
