
import { defineComponent } from "vue";

export default defineComponent({
  name: "Details",
  props: {
    label: {
      type: String,
      default: () => "",
    },
    value: {
      type: [String, Number, Object],
      default: () => "",
    },
  },
  computed: {
    caption() {
      let output: string;

      switch (typeof this.value) {
        case "object":
          output = "<table>";
          Object.entries(this.value).forEach(([key, val]) => {
            output += `<tr><td>${key.toUpperCase()}</td><td>${val}</td></tr>`;
          });
          output += "</table>";
          return output;
        case "string":
        case "number":
        default:
          return this.value.toString();
      }
    },
  },
});
