
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import {
  IonPage,
  IonHeader,
  IonTitle,
  IonContent,
  IonToolbar,
  IonButtons,
  IonRow,
  IonCol,
  modalController,
} from "@ionic/vue";
import { close } from "ionicons/icons";
import { ERR } from "../utils/error";
import type { FormError } from "../utils/types";
import Button from "./Button.vue";
import FormMessage from "./FormMessage.vue";
import Icon from "./Icon.vue";
import api from "../store/api/constants";

export default defineComponent({
  name: "ChangePassword",
  components: {
    IonPage,
    IonHeader,
    IonTitle,
    IonContent,
    IonToolbar,
    IonButtons,
    IonRow,
    IonCol,
    Button,
    FormMessage,
    Icon,
  },
  data() {
    return {
      error: null as FormError | null,
    };
  },
  setup() {
    return {
      close,
    };
  },
  computed: {
    ...mapGetters("api", {
      loading: "loading",
    }),
    path: () => api.paths.deleteUser,
  },
  methods: {
    ...mapActions("api", {
      deleteUser: "deleteUser",
    }),
    ...mapActions("user", {
      message: "message",
    }),
    closeModal() {
      modalController.dismiss();
    },
    deleteUserSubmit() {
      this.clearError();
      this.deleteUser()
        .then(() => {
          this.closeModal();
        })
        .catch((error) => {
          this.showError(error);
        });
    },
    clearError() {
      this.error = null;
    },
    showError(code: string) {
      switch (code) {
        case "IMS-001":
          this.error = {
            title: "Fehler",
            message: "Das bestehende Passwort ist nicht korrekt.",
          };
          break;
        case "IMS-002":
          this.error = {
            title: "Fehler",
            message: "Das angegebene Passwort entspricht nicht den Kriterien.",
          };
          break;
        case ERR.CHANGE_PASSWORD_REQUEST.toString():
        default:
          this.error = {
            title: "Fehler",
            message:
              "Die Verbindung zum Server konnte nicht hergestellt werden.",
          };
      }
    },
  },
});
