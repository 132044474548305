
import clsx from "clsx";
import { defineComponent } from "vue";
import { IonPage, IonContent, toastController } from "@ionic/vue";
import { mapActions, mapGetters } from "vuex";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import { bugOutline, trashOutline, cameraOutline } from "ionicons/icons";
import type HTMLIonToastElement from "@ionic/core/dist/types/components";
import { ERR, ApiError, ClientError } from "../utils/error";
import type { MetaInfo } from "../utils/error";
import DpaForm from "../components/DpaForm.vue";

import {
  BrowserMultiFormatReader,
  Exception,
  DecodeHintType,
  BarcodeFormat,
} from "@zxing/library";

import codes from "../utils/codes";
import Button from "../components/Button.vue";
import Icon from "../components/Icon.vue";
import { Feature } from "../utils/types";
import { barcodeLengths } from "../utils/constants";
import type { ScanResult } from "@capacitor-community/barcode-scanner";

type ScanResultMeta = ScanResult & {
  meta?: { rows: number; cols: number };
}

export default defineComponent({
  name: "ScannerView",
  components: { IonPage, IonContent, Button, Icon, DpaForm },
  setup() {
    const hints = new Map();
    //hints.set(EncodeHintType.CHARACTER_SET, "utf-8");
    hints.set(DecodeHintType.CHARACTER_SET, "utf-8");

    const enabledFormats = [BarcodeFormat.QR_CODE, BarcodeFormat.DATA_MATRIX];

    hints.set(DecodeHintType.POSSIBLE_FORMATS, enabledFormats);

    return {
      hints,
      bugOutline,
      trashOutline,
      cameraOutline,
    };
  },
  data() {
    return {
      overlayVisible: false,
      toast: null as HTMLIonToastElement | null,
      toastCtrl: toastController,
      debug: false,
      logContent: "",
      isNative: Capacitor.isNativePlatform(),
      webReader: new BrowserMultiFormatReader(),
      dropReader: new BrowserMultiFormatReader(),
      webReaderCode: null,
    };
  },
  ionViewDidEnter() {
    this.openScanner();
  },
  ionViewWillLeave() {
    if (this.toast) {
      this.toastCtrl.dismiss(null, "cancel");
      this.toast = null;
    }

    this.closeScanner();
  },
  computed: {
    ...mapGetters("scans", {
      scans: "scans",
      selected: "selected",
    }),
    ...mapGetters("user", {
      user: "user",
      dpa: "dpa",
    }),
    debugEnabled() {
      return this.user?.features?.filter(
        (feature: Feature) => feature.type === "DEBUG" && feature.enabled
      ).length;
    },
    dropEnabled() {
      return this.user?.features?.filter(
        (feature: Feature) => feature.type === "DROP" && feature.enabled
      ).length;
    },
  },
  watch: {
    selected(val) {
      if (this.isNative) {
        if (val) {
          BarcodeScanner.pauseScanning();
        } else {
          BarcodeScanner.resumeScanning();
        }
      }
    },
  },
  methods: {
    clsx,
    ...mapActions("scans", {
      decode: "decode",
      clear: "clear",
    }),
    ...mapActions("user", {
      message: "message",
    }),
    log(str: string) {
      this.logContent = str + "<br />" + this.logContent;
      // eslint-disable-next-line
      console.log(str);
    },
    getType(code: string) {
      if (!code) {
        return;
      }

      if (this.hexEncode("http") === code.substr(0, 8)) {
        return "qr";
      }

      if (this.hexEncode("DEA") === code.substr(0, 6)) {
        return "pmc";
      }

      return "unknown";
    },
    async handleQrCode(code: string) {
      this.toast = await this.toastCtrl.create({
        header: "Möchten Sie die folgende Seite besuchen?",
        message: code,
        position: "middle",
        color: "dark",
        buttons: [
          {
            side: "start",
            text: "Ja",
            handler: async () => {
              await Browser.open({ url: code });
            },
          },
          {
            text: "Nein",
            role: "cancel",
          },
        ],
      });
      return this.toast.present();
    },
    async handleUnknownCode() {
      this.toast = await this.toastCtrl.create({
        message: "Unbekannter Code.",
        duration: 2000,
        position: "middle",
        color: "dark",
      });
      return this.toast.present();
    },
    async scan(type: "qr" | "pmc" | "invalid") {
      if (type === "qr") {
        this.handleScan("https://www.bauer-kirch.de");
      } else {
        this.handleScan(codes[Math.floor(Math.random() * codes.length)]);
      }
    },
    hexEncode(str: string) {
      let result = "";
      for (let i = 0; i < str.length; i++) {
        result += str.charCodeAt(i).toString(16).padStart(2, "0");
      }

      return result;
    },

    hexDecode(str: string) {
      let result = "";

      for (let i = 0; i < str.length; i += 2) {
        result += String.fromCharCode(parseInt(str.substr(i, 2), 16));
      }

      return result;
    },

    getSize(length: number) {
      let result = null;
      if (Object.values(barcodeLengths).includes(length - 2)) {
        Object.values(barcodeLengths).find((item, index) => {
          if (item === length - 2) {
            const size = Object.keys(barcodeLengths)[index].split("x");

            result = {
              rows: size[0],
              cols: size[1],
            };
          }
        });
      }

      return result;
    },

    async openScanner() {
      this.showOverlay();
      this.hideBackground();

      try {
        if (this.isNative) {
          await this.checkPermission();

          await BarcodeScanner.startScanning({}, (data: ScanResultMeta) => {
            if (data.hasContent && data.content) {
              if (this.selected) {
                return;
              }

              if (!this.dpa) {
                return;
              }

              this.handleScan(
                this.hexEncode(data.content).toLowerCase(),
                data.meta || null
              );
            }
          });
        } else {
          this.webReader = new BrowserMultiFormatReader(this.hints);
          const video: HTMLVideoElement =
            (this.$refs?.video as HTMLVideoElement) || new HTMLVideoElement();
          const isMediaStreamAPISupported =
            navigator &&
            navigator.mediaDevices &&
            "enumerateDevices" in navigator.mediaDevices;

          if (!isMediaStreamAPISupported) {
            throw new Exception("Media Stream API is not supported");
          }

          if (this.$refs?.video) {
            this.webReader
              .decodeFromVideoDevice(null, video, (result) => {
                if (result) {
                  if (this.selected) {
                    return;
                  }

                  const rawBytes = result.getRawBytes();
                  const size = this.getSize(rawBytes.length);

                  this.handleScan(rawBytes, size || null);
                }
              })
              .catch((reason) => {
                switch (reason.toString()) {
                  case "NotReadableError: Could not start video source":
                    this.showError("1001");
                    break;
                  case "NotAllowedError: Permission denied":
                    this.showError("1002");
                    break;
                  default:
                    this.showError("1000");
                }
              });
          }
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
      }
    },
    async handleScan(
      code: string | Uint8Array,
      meta?: { cols: number; rows: number } | null
    ) {
      if (this.toast) {
        this.toastCtrl.dismiss(null, "cancel");
        this.toast = null;
      }

      if (typeof code !== "string") {
        const snapshot = this.takeSnapshot();
        this.decode({
          type: "raw",
          code: code.join(":"),
          meta,
          snapshot: snapshot,
        }).catch((error: ApiError | ClientError) => {
          this.showError(error.code || ERR.DECODE_RESPONSE, error.meta || {});
        });
        return;
      }

      if (this.getType(code) === "qr") {
        this.handleQrCode(this.hexDecode(code));
      } else {
        const snapshot = this.takeSnapshot();
        this.decode({
          type: "hex",
          code: code,
          meta,
          snapshot: snapshot,
        }).catch((error: ApiError | ClientError) => {
          this.showError(error.code || ERR.DECODE_RESPONSE, error.meta || {});
        });
      }
    },
    async checkPermission() {
      const status = await BarcodeScanner.checkPermission({ force: false });
      if (status.granted) {
        // user granted permission
        return true;
      }

      if (status.denied) {
        const c = confirm(
          "Der PMC Decoder benötigt die Erlaubnis zur Benutzung Ihrer Kamera. Diese können Sie in den App-Einstellungen erteilen."
        );
        if (c) {
          BarcodeScanner.openAppSettings();
        }
        // user denied permission
        return false;
      }

      if (status.asked) {
        // system requested the user for permission during this call
        // only possible when force set to true
      }

      if (status.neverAsked) {
        // user has not been requested this permission before
        // it is advised to show the user some sort of prompt
        // this way you will not waste your only chance to ask for the permission
        const c = confirm(
          "Der PMC Decoder benötigt die Erlaubnis zur Benutzung Ihrer Kamera."
        );
        if (!c) {
          return false;
        }
      }

      if (status.restricted || status.unknown) {
        // ios only
        // probably means the permission has been denied
        return false;
      }

      // user has not denied permission
      // but the user also has not yet granted the permission
      // so request it
      const statusRequest = await BarcodeScanner.checkPermission({
        force: true,
      });

      if (statusRequest.asked) {
        // system requested the user for permission during this call
        // only possible when force set to true
      }

      if (statusRequest.granted) {
        // the user did grant the permission now
        return true;
      }

      // user did not grant the permission, so he must have declined the request
      return false;
    },
    takeSnapshot() {
      if (this.isNative) {
        return null;
      } else {
        const video = this.$refs?.video as HTMLVideoElement;
        const canvas = document.createElement("canvas");

        const videoSize = Math.min(video.videoWidth, video.videoHeight) * 0.8;
        const canvasSize = Math.min(video.clientWidth, video.clientHeight);

        canvas.height = canvasSize;
        canvas.width = canvasSize;

        const ctx = canvas.getContext("2d");
        if (ctx && video) {
          ctx.drawImage(
            video,
            (video.videoWidth - videoSize) / 2,
            (video.videoHeight - videoSize) / 2,
            videoSize,
            videoSize,
            0,
            0,
            canvas.width,
            canvas.height
          );
        }

        var dataURI = canvas.toDataURL("image/jpeg");

        return dataURI || null;
      }
    },
    dragenter(event: any) {
      event.preventDefault();
    },
    dragover(event: any) {
      event.preventDefault();
    },
    async drop(event: any) {
      const data = event.dataTransfer.items;
      this.dropReader = new BrowserMultiFormatReader(this.hints);

      for (let i = 0; i < data.length; i += 1) {
        if (data[i].kind === "file" && data[i].type.match("^image/")) {
          try {
            const fr = new FileReader();
            fr.onload = () => {
              const img = document.createElement("img");
              if (fr.result) {
                img.onload = () => {
                  try {
                    this.dropReader.decodeFromImage(img).then((result) => {
                      if (result) {
                        if (this.selected) {
                          return;
                        }
                        const rawBytes = result.getRawBytes();
                        const size = this.getSize(rawBytes.length);

                        this.handleScan(rawBytes, size || null);
                      }
                    });
                  } catch (error) {
                    // eslint-disable-next-line
                    console.log(error);
                  }
                };
                img.src = fr.result.toString();
              }
            };
            fr.readAsDataURL(data[i].getAsFile());
          } catch (error) {
            // eslint-disable-next-line
            console.log(error);
          }
        }
      }
    },
    closeScanner() {
      this.log("close scanner");

      this.hideOverlay();
      this.showBackground();

      if (this.isNative) {
        try {
          BarcodeScanner.stopScan();
        } catch (error) {
          // eslint-disable-next-line
          console.log(error);
        }
      } else {
        this.webReader.reset();
      }
    },
    showBackground() {
      if (this.isNative) {
        try {
          BarcodeScanner.showBackground();
        } catch (error) {
          // eslint-disable-next-line
          console.log(error);
        }
      }
    },
    hideBackground() {
      if (this.isNative) {
        try {
          BarcodeScanner.hideBackground();
        } catch (error) {
          // eslint-disable-next-line
          console.log(error);
        }
      }
    },
    showOverlay() {
      this.overlayVisible = true;
    },
    hideOverlay() {
      this.overlayVisible = false;
    },
    showError(code: string, meta: MetaInfo = {}) {
      switch (code) {
        case "803":
        case "8404":
          this.message({
            title: "Post-DataMatrix-Code nicht unterstützt",
            message: `Der gescannte Post-DataMatrix-Code ${
              meta ? "(Frankierart/Version: " + meta.type + ")" : ""
            } wird vom System aktuell nicht unterstützt.`,
            color: "warning",
          });
          break;
        case "8401":
          this.message({
            title: "Ungültiger Post-DataMatrix-Code",
            message:
              "Beim gescannten Code handelt es sich nicht um einen gültigen Post-DataMatrix-Code.",
            color: "danger",
          });
          break;
        case "8402":
          this.message({
            title: "Ungültiger Post-DataMatrix-Code",
            message: `Der Post-DataMatrix-Code enthält ungültige Zeichen${
              meta ? " (" + meta.type + ")" : ""
            }.`,
            color: "warning",
          });
          break;
        case "8403":
          this.message({
            title: "Spezifikation nicht freigegeben",
            message: `Für den gescannten Post-DataMatrix-Code ${
              meta ? "(Frankierart/Version: " + meta.type + ")" : ""
            } liegt keine offiziell freigegebene Spezifikation vor.`,
            color: "warning",
          });
          break;
        case "8405":
          this.message({
            title: "Ungültiger Post-DataMatrix-Code",
            message: `Der Post-DataMatrix-Code weist eine ungültige Länge ${
              meta && meta.length ? "(" + meta.length + ")" : ""
            } ${
              meta && meta.type
                ? " für die Frankierart/Version " + meta.type + ""
                : ""
            } auf.`,
            color: "warning",
          });
          break;
        case "8406":
          this.message({
            title: "Ungültiger Post-DataMatrix-Code",
            message: `Der Post-DataMatrix-Code weist eine ungültige Größe ${
              meta && meta.rows && meta.cols
                ? "(" + meta.rows + "x" + meta.cols + ")"
                : ""
            } ${
              meta && meta.type
                ? " für die Frankierart/Version " + meta.type + ""
                : ""
            } auf.`,
            color: "warning",
          });
          break;
        case "1000":
        case "1001":
          this.message({
            message:
              "Es steht keine Kamera zur Verfügung. Bitte prüfen Sie die Kameraeinstellungen.",
            color: "warning",
          });
          break;
        case "1002":
          this.message({
            message:
              "Der Zugriff auf die Kamera ist nicht möglich. Bitte prüfen Sie die Berechtigungseinstellungen.",
            color: "warning",
          });
          break;
        default:
          this.message({
            message:
              "Die Verbindung zu Server konnte nicht hergestellt werden.",
            color: "danger",
          });
      }
    },
  },
});
