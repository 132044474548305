<template>
  <rect
    v-motion
    :initial="{
      opacity: 0,
      y: 100,
    }"
    :enter="{
      opacity: 1,
      y: 0,
      transition: {
        delay: delay * 40,
      },
    }"
    width="8"
    height="8"
    rx="2"
    ry="2"
    :x="x"
    :y="y"
  />
</template>

<script>
export default {
  name: "Logo",
  props: {
    x: Number,
    y: Number,
    delay: Number,
  },
};
</script>
