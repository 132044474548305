import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21745294"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createVNode(_component_Icon, { icon: _ctx.personOutline }, null, 8, ["icon"]),
    _createVNode(_component_router_link, { to: "/account" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.user.name ? _ctx.user.name : _ctx.user.username), 1)
      ]),
      _: 1
    })
  ]))
}