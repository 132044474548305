
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { IonTabBar, IonTabButton, IonLabel, IonBadge } from "@ionic/vue";
import {
  home,
  camera,
  informationCircleOutline,
  list,
  personOutline,
} from "ionicons/icons";
import Icon from "./Icon.vue";
import { versions } from "../store/api/state";

export default defineComponent({
  name: "TabsLayout",
  components: {
    IonLabel,
    IonTabBar,
    IonTabButton,
    IonBadge,
    Icon,
  },
  props: {
    disabled: Boolean,
  },
  setup() {
    return {
      versions,
      home,
      camera,
      list,
      personOutline,
      informationCircleOutline,
    };
  },
  computed: {
    ...mapGetters("api", {
      version: "version",
    }),
    ...mapGetters("user", {
      user: "user",
    }),
  },
});
