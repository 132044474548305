import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f2fb8e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login" }
const _hoisted_2 = {
  key: 0,
  class: "ion-padding"
}
const _hoisted_3 = { class: "ion-text-center ion-no-padding" }
const _hoisted_4 = {
  key: 1,
  class: "ion-padding"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_LoginForm = _resolveComponent("LoginForm")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ResetPasswordForm = _resolveComponent("ResetPasswordForm")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createVNode(_component_ion_toolbar, { color: "medium" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_buttons, { slot: "start" }, {
          default: _withCtx(() => [
            _createVNode(_component_Button, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectMode('login'))),
              class: _normalizeClass({ selected: _ctx.mode === 'login' })
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Login ")
              ]),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_Button, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectMode('reset-password'))),
              class: _normalizeClass({ selected: _ctx.mode === 'reset-password' })
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Passwort vergessen ")
              ]),
              _: 1
            }, 8, ["class"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.mode === 'login')
      ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
          _createVNode(_component_LoginForm, {
            onSuccess: _ctx.closePopover,
            onCancel: _ctx.closePopover
          }, null, 8, ["onSuccess", "onCancel"]),
          _createVNode(_component_ion_text, { class: "ion-no-padding" }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_3, [
                _createElementVNode("a", {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectMode('reset-password'))),
                  class: "ion-cursor-pointer"
                }, "Passwort vergessen")
              ])
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("section", _hoisted_4, [
          _createVNode(_component_ResetPasswordForm, {
            onSuccess: _ctx.closePopover,
            onCancel: _ctx.closePopover
          }, null, 8, ["onSuccess", "onCancel"])
        ]))
  ]))
}