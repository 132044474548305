
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";
import { qrCodeOutline, trashOutline } from "ionicons/icons";
import formatDate from "../utils/date";
import renderAsText from "../utils/text";
import ScanActions from "./ScanActions.vue";
import Icon from "./Icon.vue";

export default defineComponent({
  name: "ScanItem",
  components: {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonCardSubtitle,
    IonGrid,
    IonRow,
    IonCol,
    Icon,
    ScanActions,
  },
  setup() {
    return {
      qrCodeOutline,
      trashOutline,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    renderAsText,
    formatDate,
    ...mapActions("scans", {
      select: "select",
    }),
  },
});
