import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createBlock(_component_ion_icon, {
    icon: _ctx.icon,
    name: _ctx.name,
    size: _ctx.size,
    class: _normalizeClass({ medium: _ctx.size === 'medium' })
  }, null, 8, ["icon", "name", "size", "class"]))
}