
import { defineComponent } from "vue";
import { IonCard } from "@ionic/vue";
import { mapActions } from "vuex";
import { trashOutline, menuOutline, close } from "ionicons/icons";
import formatDate from "../utils/date";
import ListItem from "./ListItem.vue";

export default defineComponent({
  name: "List",
  components: {
    IonCard,
    ListItem,
  },
  setup() {
    return {
      trashOutline,
      menuOutline,
      close,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate,
    ...mapActions("scans", {
      select: "select",
      remove: "remove",
    }),
  },
});
