import { createRouter, createWebHistory } from "@ionic/vue-router";
import store from "@/store";

import { RouteRecordRaw } from "vue-router";

import HomeView from "@/views/Home.vue";
import ScannerView from "@/views/Scanner.vue";
import HistoryView from "@/views/History.vue";
import AboutView from "@/views/About.vue";
import AccountView from "@/views/Account.vue";
import ImprintView from "@/views/Imprint.vue";
import PrivacyView from "@/views/Privacy.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    name: "full",
    path: "/full",
    component: HomeView,
    beforeEnter: (to, from, next) => {
      store.dispatch("api/setVersion", "FULL");
      next({ path: "/home" });
    },
  },
  {
    name: "lite",
    path: "/lite",
    component: HomeView,
    beforeEnter: (to, from, next) => {
      store.dispatch("api/setVersion", "LITE");
      next({ path: "/home" });
    },
  },
  {
    name: "home",
    path: "/home",
    component: HomeView,
  },
  {
    name: "scanner",
    path: "/scanner",
    component: ScannerView,
  },
  {
    name: "history",
    path: "/history",
    component: HistoryView,
  },
  {
    name: "about",
    path: "/about",
    component: AboutView,
  },
  {
    name: "imprint",
    path: "/imprint",
    component: ImprintView,
  },
  {
    name: "privacy",
    path: "/privacy",
    component: PrivacyView,
  },
  {
    name: "account",
    path: "/account",
    component: AccountView,
    beforeEnter: (to, from, next) => {
      if (store.getters["user/user"]) {
        next();
      } else {
        next({ path: "/home" });
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
