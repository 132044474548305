
import { defineComponent } from "vue";
import { IonText, IonToolbar, IonButtons, popoverController } from "@ionic/vue";
import LoginForm from "./LoginForm.vue";
import ResetPasswordForm from "./ResetPasswordForm.vue";
import Button from "./Button.vue";

export default defineComponent({
  name: "LoginView",
  components: {
    IonText,
    IonToolbar,
    IonButtons,
    LoginForm,
    ResetPasswordForm,
    Button,
  },
  data() {
    return {
      mode: "login",
    };
  },
  methods: {
    selectMode(mode: "login" | "reset-password") {
      this.mode = mode;
    },
    closePopover() {
      popoverController.dismiss();
    },
  },
});
