
import { defineComponent } from "vue";
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from "@ionic/vue";
import Brand from "../components/Brand.vue";
import Products from "../components/Products.vue";
import Sponsor from "../components/Sponsor.vue";
import Imprint from "../components/Imprint.vue";
import { Capacitor } from "@capacitor/core";

export default defineComponent({
  name: "HistoryView",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    Brand,
    Products,
    Sponsor,
    Imprint,
  },
  data() {
    return {
      avv: false,
      isNative: Capacitor.isNativePlatform(),
    };
  },
});
