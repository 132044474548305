
import clsx from "clsx";
import { close, checkmark } from "ionicons/icons";
import { defineComponent } from "vue";
import Icon from "./Icon.vue";

export default defineComponent({
  name: "RegisterForm",
  components: {
    Icon,
  },
  props: {
    password: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      checkmark,
      close,
    };
  },
  methods: {
    clsx,
    validateField(value: string, validator: string) {
      if (!value) {
        return false;
      }
      if (value.trim() === "") {
        return false;
      }
      switch (validator) {
        case "min-length":
          return value.length >= 12;
        case "uppercase":
          return /(?=.*[A-Z])/.test(value);
        case "lowercase":
          return /(?=.*[a-z])/.test(value);
        case "numeric":
          return /(?=.*[0-9])/.test(value);
        default:
          return false;
      }
    },
  },
});
