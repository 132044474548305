import { ApiError } from "../../utils/error";
import { CancelTokenSource } from "axios";

export type Version = "LITE" | "DEMO" | "FULL";

export const versions = {
  LITE: "LITE",
  DEMO: "DEMO",
  FULL: "FULL",
};

export interface Auth {
  created: number;
  expires: number;
  token: string;
}

export class State {
  version: Version | null = "FULL";
  loading: string | boolean = false;
  error: ApiError | null = null;
  request: CancelTokenSource | null = null;
  auth: Auth | null = null;
  retry = false;
}

export default new State();
