import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SplashScreen = _resolveComponent("SplashScreen")!
  const _component_Layout = _resolveComponent("Layout")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, { class: "app" }, {
    default: _withCtx(() => [
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.splashScreen && _ctx.home)
            ? (_openBlock(), _createBlock(_component_SplashScreen, {
                key: 0,
                onReady: _cache[0] || (_cache[0] = ($event: any) => (_ctx.disableSplashScreen()))
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_Layout)
    ]),
    _: 1
  }))
}