export const TYPES = {
  WSG: "WSG",
  IMS: "IMS",
};

export const ERR = {
  NETWORK_AUTH: "101",
  NETWORK_REQUEST: "102",
  NETWORK_RESPONSE: "103",
  LOGIN_AUTH: "201",
  LOGIN_REQUEST: "202",
  LOGIN_RESPONSE: "203",
  LOGIN_UNVERIFIED: "206",
  RESET_PASSWORD_REQUEST: "302",
  REGISTER_REQUEST: "402",
  REGISTER_ALREADY_EXISTS: "403",
  CHANGE_PASSWORD_REQUEST: "502",
  VERIFY_USER_REQUEST: "602",
  DECODE_REQUEST: "802",
  DECODE_RESPONSE: "803",
  DECODE_RESPONSE_INVALID: "8401",
  DECODE_RESPONSE_NOT_ALLOWED: "8403",
  DECODE_RESPONSE_NOT_SUPPORTED: "8404",
  DECODE_RESPONSE_INVALID_LENGTH: "8405",
  DECODE_RESPONSE_INVALID_SIZE: "8406",
};

export type MetaInfo = {
  type?: number;
  length?: number;
  rows?: number;
  cols?: number;
};

export declare class Error {
  public name: string;
  public stack: string;
  public date: Date;
  public code?: string | null;
  public message?: string | null;
  public title?: string | null;
  public meta?: MetaInfo | null;
  constructor(message?: string | null);
}

export class ApiError extends Error {
  constructor(code: string, title?: string | null, message?: string | null) {
    super();

    this.name = "ApiError";
    this.code = code;
    this.title = title;
    this.message = message;

    this.date = new Date();
  }

  getCode = () => this.code;
  isVisibleToUser = () => {
    return false;
  };
}

export class ClientError extends Error {
  constructor(
    code: string,
    title?: string | null,
    message?: string | null,
    meta?: MetaInfo | null
  ) {
    super();

    this.name = "ClientError";
    this.code = code;
    this.meta = meta;
    this.title = title;
    this.message = message;

    this.date = new Date();
  }

  getCode = () => this.code;
}
