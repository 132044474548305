import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createBlock(_component_ion_footer, { class: "footer" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, { color: "tertiary" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_buttons, { class: "buttons" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, {
                href: "/imprint",
                onClick: _ctx.imprint,
                fill: "clear",
                size: "small"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Impressum ")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ion_button, {
                href: "/privacy",
                onClick: _ctx.privacy,
                fill: "clear",
                size: "small"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Datenschutz ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}