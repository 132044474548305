import * as yup from "yup";
import { AnyObject, Maybe } from "yup/lib/types";

yup.addMethod<yup.StringSchema>(
  yup.string,
  "upperCaseCharacter",
  function (errorMessage) {
    return this.test("upperCaseCharacter", errorMessage, function (value) {
      const { path, createError } = this;

      return value
        ? /(?=.*[A-Z])/.test(value)
        : false || createError({ path, message: errorMessage });
    });
  }
);

yup.addMethod<yup.StringSchema>(
  yup.string,
  "lowerCaseCharacter",
  function (errorMessage) {
    return this.test("lowerCaseCharacter", errorMessage, function (value) {
      const { path, createError } = this;

      return value
        ? /(?=.*[a-z])/.test(value)
        : false || createError({ path, message: errorMessage });
    });
  }
);

yup.addMethod<yup.StringSchema>(
  yup.string,
  "numericCharacter",
  function (errorMessage) {
    return this.test("numericCharacter", errorMessage, function (value) {
      const { path, createError } = this;

      return value
        ? /(?=.*[0-9])/.test(value)
        : false || createError({ path, message: errorMessage });
    });
  }
);

declare module "yup" {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    upperCaseCharacter(errorMessage?: string): StringSchema<TType, TContext>;
    lowerCaseCharacter(errorMessage?: string): StringSchema<TType, TContext>;
    numericCharacter(errorMessage?: string): StringSchema<TType, TContext>;
  }
}

export default yup;
