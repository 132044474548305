
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "AccountInfo",
  computed: {
    ...mapGetters("user", {
      user: "user",
    }),
  },
});
