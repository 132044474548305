
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import ForgotPasswordRequestForm from "./ForgotPasswordRequestForm.vue";
import ForgotPasswordVerifyForm from "./ForgotPasswordVerifyForm.vue";
import ForgotPasswordChangeForm from "./ForgotPasswordChangeForm.vue";

export default defineComponent({
  name: "ResetPassword",
  emits: ["success", "cancel"],
  components: {
    ForgotPasswordRequestForm,
    ForgotPasswordVerifyForm,
    ForgotPasswordChangeForm,
  },
  computed: {
    ...mapGetters("user", {
      passwordRequest: "passwordRequest",
    }),
  },
});
