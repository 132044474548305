import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3bc1a4d7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ loading: true, absolute: !_ctx.inline, small: _ctx.small })
  }, [
    _createElementVNode("ul", _hoisted_1, [
      _createElementVNode("li", {
        class: "square top",
        style: _normalizeStyle({ backgroundColor: _ctx.color ? _ctx.color : 'white !important' })
      }, null, 4),
      _createElementVNode("li", {
        class: "square right",
        style: _normalizeStyle({ backgroundColor: _ctx.color ? _ctx.color : 'white !important' })
      }, null, 4),
      _createElementVNode("li", {
        class: "square bottom",
        style: _normalizeStyle({ backgroundColor: _ctx.color ? _ctx.color : 'white !important' })
      }, null, 4),
      _createElementVNode("li", {
        class: "square left",
        style: _normalizeStyle({ backgroundColor: _ctx.color ? _ctx.color : 'white !important' })
      }, null, 4)
    ])
  ], 2))
}