
import clsx from "clsx";
import { defineComponent } from "vue";
import { IonGrid, IonLabel, IonInput, IonRow, IonCol } from "@ionic/vue";

import { mapActions, mapGetters } from "vuex";
import { Form, Field, ErrorMessage } from "vee-validate";
import { ERR, ApiError, ClientError } from "../utils/error";
import PasswordValidator from "./PasswordValidator.vue";
import type { FormError } from "../utils/types";
import yup from "../utils/yup";
import FormMessage from "./FormMessage.vue";
import Button from "./Button.vue";
import api from "../store/api/constants";

export default defineComponent({
  name: "RegisterForm",
  emits: ["success", "cancel"],
  components: {
    IonGrid,
    IonLabel,
    IonInput,
    IonRow,
    IonCol,
    Button,
    Form,
    Field,
    ErrorMessage,
    PasswordValidator,
    FormMessage,
  },
  data() {
    return {
      values: {
        current: "",
        password: "",
        repassword: "",
      },
      error: null as FormError | null,
    };
  },
  setup() {
    const schema = yup.object({
      current: yup.string().required(),
      password: yup
        .string()
        .upperCaseCharacter()
        .lowerCaseCharacter()
        .numericCharacter()
        .min(12, "min-length")
        .required("required")
        .strict(),
      repassword: yup
        .string()
        .required()
        .oneOf([yup.ref("password")]),
    });

    return {
      schema,
    };
  },
  mounted() {
    this.clearError();
  },
  computed: {
    ...mapGetters("api", {
      loading: "loading",
    }),
    path: () => api.paths.changePassword,
  },
  methods: {
    clsx,
    ...mapActions("api", {
      changePassword: "changePassword",
    }),
    ...mapActions("user", {
      message: "message",
    }),
    changePasswordSubmit(values: any) {
      this.clearError();

      this.changePassword({
        current: values.current,
        password: values.password,
      })
        .then(() => {
          this.message({
            title: "Änderung erfolgreich",
            message: "Ihr Passwort wurde erfolgreich geändert.",
            color: "success",
          });

          this.$emit("success");
        })
        .catch((error: ApiError | ClientError) => {
          this.showError(error.code ?? "");
        });
    },
    clearError() {
      this.error = null;
    },
    showError(code: string) {
      switch (code) {
        case "IMS-001":
          this.error = {
            message: "Das bestehende Passwort ist nicht korrekt.",
          };
          break;
        case "IMS-002":
          this.error = {
            message: "Das angegebene Passwort entspricht nicht den Kriterien.",
          };
          break;
        case ERR.CHANGE_PASSWORD_REQUEST.toString():
        default:
          this.error = {
            title: "Fehler",
            message:
              "Die Verbindung zum Server konnte nicht hergestellt werden.",
          };
      }
    },
    cancel() {
      this.$emit("cancel");
    },
  },
});
