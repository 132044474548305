
import { defineComponent } from "vue";

export default defineComponent({
  name: "Loading",
  props: {
    color: String,
    inline: Boolean,
    small: Boolean,
  },
});
