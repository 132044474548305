
import { defineComponent } from "vue";
import { IonButton, IonFabButton } from "@ionic/vue";
import Loading from "./Loading.vue";

export default defineComponent({
  name: "Button",
  components: {
    IonFabButton,
    IonButton,
    Loading,
  },
  props: {
    variant: String,
    shape: String,
    type: {
      type: String,
      default: () => "submit",
    },
    color: String,
    expand: String,
    size: String,
    href: String,
    shadow: Boolean,
    disabled: Boolean,
    loading: Boolean,
    strong: Boolean,
  },
});
