import { format } from "date-fns";

export interface DateObject {
  year: number;
  month: number;
  day: number;
}

export interface TimeObject {
  hour: number;
  minute: number;
}

const formatDate = (date: DateObject, time?: TimeObject | null) => {
  const ts = new Date(
    date.year,
    date.month - 1,
    date.day,
    time ? time.hour : 0,
    time ? time.minute : 0
  );
  if (time) {
    return format(ts, "dd.MM.yyyy HH:mm");
  }

  return format(ts, "dd.MM.yyyy");
};

export default formatDate;
